// BarcodeScanDialog
import React from 'react';
import { Button, Dialog, DialogFooter } from '@blueprintjs/core';
import Html5QrcodePlugin from './Html5QRCodePlugin';

interface Props {
  isSerialModalOpen?: boolean;
  handleCloseModal?: () => void;
  isScanning?: boolean;
  detectedText?: string;
  scanError?: string;
  setScannedText: React.Dispatch<React.SetStateAction<string>>;
  title?: string;
}

const BarcodeScanDialog: React.FC<Props> = ({
  isSerialModalOpen,
  handleCloseModal,
  detectedText,
  scanError,
  setScannedText,
  title,
}) => {
  return (
    <Dialog
      title={title}
      isOpen={isSerialModalOpen}
      onClose={handleCloseModal}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <p
          style={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: '14px',
            marginBottom: '10px',
          }}
        >
          Please place your barcode inside the rectangle
        </p>
        
      </div>
      <Html5QrcodePlugin 
          fps={10}
          qrbox={{ width: 400, height: 100 }}
          disableFlip={true}
          qrCodeSuccessCallback={setScannedText}
        />
        {detectedText && (
          <p
            style={{
              color: 'green',
              fontWeight: 'bold',
              fontSize: '14px',
              marginTop: '20px',
              alignSelf: 'center',
            }}
          >
            Code scanned: {detectedText}
          </p>
        )}
        {scanError && <p style={{ color: 'red' }}>{scanError}</p>}
      <DialogFooter
        actions={[
          <Button
            key="confirm"
            onClick={handleCloseModal}
            intent="success"
            disabled={!detectedText}
          >
            Confirm Scan
          </Button>,
        ]}
      />
    </Dialog>
  );
};

export default BarcodeScanDialog;
