import React from 'react';
import { Col, Row } from 'antd';
import { Button, Drawer } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { isMobile } from 'react-device-detect';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import Typography from '@core/components/Typography';
import DetailViewContextProvider from '@core/components/DetailViewContextProvider';
import FeatureCard from '@netomnia/modules/ProjectModule/views/RFCManagementViewV2/FeatureCard';

interface Props {
  visible: boolean;
  onClose: () => void;
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
}

const BoardCardDrawer: React.FC<Props> = ({ visible, onClose, record, schema }) => {
  return (
    <Drawer
      lazy
      canEscapeKeyClose
      title={
        <Row justify="space-between" align="middle">
          <Col>
            <Typography stronger style={{ fontSize: 16 }}>
              Quick View
            </Typography>
          </Col>
          <Col>
            <Link to={getBrowserPath(record) || ''} target={'_blank'}>
              <Button
                disabled={!record}
                text="View Full Record"
                intent="primary"
                small
                style={{ marginRight: 10 }}
              />
            </Link>
          </Col>
        </Row>
      }
      isOpen={visible}
      onClose={onClose}
      size={isMobile ? '100%' : '80%'}
      className="recordDrawer"
    >
      <Row style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Col span={24}>
          {/* TODO: in the future, make this flexible by using a Kanban Router if need be */}
          <DetailViewContextProvider
            record={record}
            schema={schema}
            pipeline={undefined}
          >
            <FeatureCard />
          </DetailViewContextProvider>
        </Col>
      </Row>
    </Drawer>
  );
};

export default BoardCardDrawer; 
