export const MY_CASES_SET_CASES = 'MY_CASES_SET_CASES';
export const MY_CASES_SET_SELECTED_CASE = 'MY_CASES_SET_SELECTED_CASE';
export const MY_CASES_SET_SELECTED_CASE_MESSAGES = 'MY_CASES_SET_SELECTED_CASE_MESSAGES';
export const MY_CASES_SET_SELECTED_CASE_NOTES = 'MY_CASES_SET_SELECTED_CASE_NOTES';
export const MY_CASES_SET_SELECTED_CASE_EMAILS = 'MY_CASES_SET_SELECTED_CASE_EMAILS';
export const MY_CASES_SET_SELECTED_CASE_CONVERSATION = 'MY_CASES_SET_SELECTED_CASE_CONVERSATION';
export const MY_CASES_TOGGLE_NOTE_EDITOR = 'MY_CASES_TOGGLE_NOTE_EDITOR';
export const MY_CASES_TOGGLE_EMAIL_EDITOR = 'MY_CASES_TOGGLE_EMAIL_EDITOR';
export const MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL = 'MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL';
export const MY_CASES_SET_ACTIVE_TAB = 'MY_CASES_SET_ACTIVE_TAB';
export const MY_CASES_UPDATE_CASE = 'MY_CASES_UPDATE_CASE';
export const MY_CASES_ADD_NOTE_TO_SELECTED_CASE = 'MY_CASES_ADD_NOTE_TO_SELECTED_CASE';
export const MY_CASES_SET_CONTACT = 'MY_CASES_SET_CONTACT';
export const MY_CASES_ADD_MESSAGE = 'MY_CASES_ADD_MESSAGE';
export const MY_CASES_ADD_EMAIL = 'MY_CASES_ADD_EMAIL';
export const MY_CASES_SET_FEED_CONTAINER_REF = 'MY_CASES_SET_FEED_CONTAINER_REF';
export const MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE =
  'MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE';
export const MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT =
  'MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT';
export const MY_CASES_SET_ASSOCIATED_ACCOUNTS = 'MY_CASES_SET_ASSOCIATED_ACCOUNTS';
export const MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT =
  'MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT';
export const MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES =
  'MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES';
export const MY_CASES_ADD_NOTE = 'MY_CASES_ADD_NOTE';
export const MY_CASES_SET_SELECTED_ACCOUNT = 'MY_CASES_SET_SELECTED_ACCOUNT';
export const MY_CASES_SET_SINGLE_CASE_MODE = 'MY_CASES_SET_SINGLE_CASE_MODE';
export const MY_CASES_SET_SELECTED_CASE_CALLS = 'MY_CASES_SET_SELECTED_CASE_CALLS';
export const MY_CASES_SET_AGENT_INPUT_TEXT = 'MY_CASES_SET_AGENT_INPUT_TEXT';
export const MY_CASES_SET_CLIENT_TYPING_TEXT = 'MY_CASES_SET_CLIENT_TYPING_TEXT';
export const MY_CASES_SET_AGENT_TYPING_TEXT = 'MY_CASES_SET_AGENT_TYPING_TEXT';
export const MY_CASES_TOGGLE_MESSAGE_EDITOR = 'MY_CASES_TOGGLE_MESSAGE_EDITOR';
export const MY_CASES_RESET_CURRENT_CASE_COUNT = 'MY_CASES_RESET_CURRENT_CASE_COUNT';
export const MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT =
  'MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT';
export const MY_CASES_SET_CASE_UNREAD_STATUS = 'MY_CASES_SET_CASE_UNREAD_STATUS';
export const MY_CASES_SET_MY_CASES_SEARCH_QUERY = 'MY_CASES_SET_MY_CASES_SEARCH_QUERY';
export const MY_CASES_SET_IS_LOADING_ACTIVITY = 'MY_CASES_SET_IS_LOADING_ACTIVITY';
