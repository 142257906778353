import Editor from '@monaco-editor/react';
import { Col, Row } from 'antd';
import { FC, useContext, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { displayMessage } from '@legacy/core/messages/store/reducers';
import { ISchemaActionUpdate, updateSchemaAction } from '@legacy/core/schemas/store/actions';

import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import { isJsonString } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/helpers';
import SchemaActionInsertTemplateButton from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionInsertTemplateButton';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';

interface Props {
  updateAction: (payload: any, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

interface IFormField {
  key: string;
  name: string;
  value: string;
}

const SchemaActionJSONDefinition: FC<Props> = (props: Props) => {
  const { alertMessage, userReducer } = props;

  const { schema, schemaAction, definition, setDefinition, JSONInvalid, isSavingDefinition } =
    useContext(SchemaActionDefinitionContext);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const editorRef = useRef(null);

  function setJSONEditorTheme(monaco: any) {
    monaco.editor.defineTheme('odinstyle', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic',
        },
      ],
      colors: {},
    });
  }

  const canAddColumns = () => {
    if (schemaAction?.isStepFlow) return false;
    else if (definition && isJsonString(definition)) {
      const parsed = JSON.parse(definition);
      if (parsed?.formDefinition) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const addColumns = (columnName?: string) => {
    if (schema) {
      let formFields: any[] = [];

      const isRequired = (schemaColumn: SchemaColumnEntity) => {
        return !!schemaColumn.validators.some(
          (validator: { type: string }) => validator.type === 'REQUIRED',
        );
      };

      let sortedSchemaColumns = schema.columns?.sort(
        (a: SchemaColumnEntity, b: SchemaColumnEntity) => (a.position > b.position ? 1 : -1),
      );

      sortedSchemaColumns.forEach((column: SchemaColumnEntity) => {
        if (!column.isHidden) {
          formFields.push({
            name: column.name,
            label: column.name,
            type: 'formField',
            required: isRequired(column),
            order: column.position,
          });
        }
      });

      let parsed: any = JSON.parse(definition);

      if (columnName) {
        parsed.formDefinition[0].formFields?.push({
          name: columnName,
          label: columnName,
          type: 'formField',
          required: false,
          order: parsed.formDefinition[0].formFields?.length || 0,
        });
      } else {
        parsed.formDefinition[0].formFields = formFields;
      }

      setDefinition(JSON.stringify(parsed, null, '\t'));
    }
  };

  const handleEditorDidMount = (editor: any, monaco: any) => {
    editorRef.current = editor;
  };

  const handleItemSelect = (item: IFormField) => {
    if (item.key === 'ALL') {
      addColumns();
    } else {
      addColumns(item.name);
    }
  };

  const renderFormField: ItemRenderer<IFormField> = (
    entity,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          style={{ fontWeight: entity.key === 'ALL' ? 500 : 'auto' }}
          intent={entity.key === 'ALL' ? 'primary' : 'none'}
          disabled={modifiers.disabled}
          key={entity.name}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={entity.name}
        />
      );
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const handleSchemaActionNameSelect = (schemaAction: SchemaActionEntity) => {
    if (schemaAction) {
      navigator.clipboard.writeText(schemaAction.name);
      alertMessage({
        body: 'Schema Action name copied to clipboard',
        type: 'success',
      });
    }
  };

  let FORM_FIELDS: IFormField[] = [];

  schema?.columns?.forEach((column: SchemaColumnEntity) => {
    if (!column.isHidden) {
      FORM_FIELDS.push({
        key: column.name,
        name: column.name,
        value: column.name,
      });
    }
  });

  FORM_FIELDS.sort((a: IFormField, b: IFormField) => {
    return a.name.localeCompare(b.name);
  });

  if (searchQuery.length > 0) {
    FORM_FIELDS = FORM_FIELDS.filter((field: IFormField) => {
      return field.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  // If no search query, add a "Add All Columns" option to the top
  if (!searchQuery.length) {
    FORM_FIELDS.unshift({
      key: 'ALL',
      name: 'Insert All Form Fields',
      value: '',
    });
  }

  return (
    <Row>
      <Col span={18}>
        {schemaAction && (
          <Editor
            height="calc(100vh - 90px)"
            theme="odinstyle"
            width="100%"
            defaultLanguage="json"
            value={definition}
            defaultValue={JSON.stringify(schemaAction?.definition, null, '\t')}
            onMount={handleEditorDidMount}
            beforeMount={setJSONEditorTheme}
            onChange={(value: any) => setDefinition(value)}
            options={{
              wordWrap: 'on',
              minimap: { enabled: false },
            }}
          />
        )}
      </Col>
      <Col span={6}>
        <Row style={{ padding: 15 }}>
          <Col span={24} style={{ marginBottom: 15 }}>
            <span style={{ fontWeight: 500 }}>Definition control</span>
          </Col>

          {/* Add Template */}
          <Col span={24}>
            <SchemaActionInsertTemplateButton />
          </Col>

          {/* Add Columns */}
          {!schemaAction?.isStepFlow && (
            <Col span={24} style={{ marginTop: 15 }}>
              <Select<IFormField>
                items={FORM_FIELDS}
                fill
                resetOnClose
                disabled={
                  JSONInvalid ||
                  isSavingDefinition ||
                  !canAddColumns() ||
                  !isSystemAdmin(userReducer)
                }
                itemRenderer={renderFormField}
                noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
                onItemSelect={handleItemSelect}
                query={searchQuery}
                scrollToActiveItem
                onQueryChange={(e: any) => handleQueryChange(e)}
              >
                <Button
                  text="Insert Form Fields"
                  style={{ borderRadius: 5 }}
                  fill
                  disabled={
                    JSONInvalid ||
                    isSavingDefinition ||
                    !canAddColumns() ||
                    !isSystemAdmin(userReducer)
                  }
                />
              </Select>
            </Col>
          )}

          {/* Flow Control */}
          {schemaAction?.isStepFlow && (
            <Col span={24} style={{ marginTop: 15 }}>
              {/* <SchemaActionNameSearch
                onSelect={handleSchemaActionNameSelect}
                buttonText="Add Forms"
              /> */}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionJSONDefinition);
