import { Button, Drawer, Section, Tab, Tabs } from '@blueprintjs/core';
import { isJsonValid } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/helpers';
import SchemaActionBuilder from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionBuilder';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { ISchemaActionUpdate, updateSchemaAction } from '@legacy/core/schemas/store/actions';
import { Col, Row } from 'antd';
import { createContext, createRef, FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import SchemaActionHelpDrawer from './SchemaActionHelpDrawer';
import SchemaActionJSONDefinition from './SchemaActionJSONDefinition';
import SchemaActionPermissions from './SchemaActionPermissions';
import './styles.scss';

interface Props {
  schema: SchemaEntity | undefined;
  schemaAction: SchemaActionEntity | undefined;
  onClose?: () => void;
  onUpdate?: (action: SchemaActionEntity) => void;
  updateAction: (payload: any, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

export const SchemaActionDefinitionContext = createContext<{
  definition: any;
  parsedDefinition: any;
  schema: SchemaEntity | undefined;
  schemaAction: SchemaActionEntity | undefined;
  JSONInvalid: boolean;
  isSavingDefinition: boolean;
  setSelectedTab: (tab: 'BUILDER' | 'DEFINITION' | 'PERMISSIONS') => void;
  setDefinition: (definition: any) => void;
  builderContainerRef: any;
}>({
  definition: undefined,
  parsedDefinition: undefined,
  schema: undefined,
  schemaAction: undefined,
  JSONInvalid: false,
  isSavingDefinition: false,
  setSelectedTab: () => {},
  setDefinition: () => {},
  builderContainerRef: undefined,
});

const SchemaActionConfiguration: FC<Props> = (props: Props) => {
  const closeDrawer = () => {
    setDefinition(undefined);
    setSelectedTab('DEFINITION');

    if (props.onClose) {
      props.onClose();
    }
  };

  const { schema, schemaAction, onUpdate, updateAction, alertMessage } = props;

  const [selectedTab, setSelectedTab] = useState<'BUILDER' | 'DEFINITION' | 'PERMISSIONS'>(
    'DEFINITION',
  );
  const [definition, setDefinition] = useState<string | undefined>(undefined);
  const [parsedDefinition, setParsedDefinition] = useState<Object | undefined>(undefined);

  const [isHelpDrawerOpen, setIsHelpDrawerOpen] = useState<boolean>(false);
  const [isSavingDefinition, setIsSavingDefinition] = useState<boolean>(false);
  const [JSONInvalid, setJSONInvalid] = useState<boolean>(false);
  const [builderContainerRef, setBuilderContainerRef] = useState<any>(undefined);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    setBuilderContainerRef(ref);
  }, []);

  // Set definition when schema action is available
  useEffect(() => {
    if (schemaAction) {
      setDefinition(JSON.stringify(schemaAction.definition, null, '\t'));
    }

    if (schemaAction && schemaAction.isStepFlow) {
      setSelectedTab('BUILDER');
    }
  }, [schemaAction]);

  const closeHelpDrawer = () => {
    setIsHelpDrawerOpen(false);
  };

  const parseDefinition = async (definition: string) => {
    try {
      const parsed = JSON.parse(definition);
      setParsedDefinition(parsed);
    } catch (error) {
      setParsedDefinition(undefined);
    }
  };

  // Handle JSON validation on definition change
  useEffect(() => {
    if (definition && definition?.length! > 0 && isJsonValid(definition)) {
      setJSONInvalid(false);
      parseDefinition(definition);
    } else if (definition?.length !== 0 && !isJsonValid(definition)) {
      setJSONInvalid(true);
      setParsedDefinition(undefined);
    } else {
      setJSONInvalid(false);
    }
  }, [definition]);

  const saveDefinition = () => {
    if (schemaAction && definition) {
      setIsSavingDefinition(true);
      updateAction(
        {
          ...schemaAction,
          schemaActionId: schemaAction.id,
          definition: JSON.parse(definition),
        },
        (res: SchemaActionEntity) => {
          alertMessage({ body: 'Schema Action Updated', type: 'success' });
          console.log('debug: Schema Action Updated', res);
          setIsSavingDefinition(false);
          onUpdate && onUpdate(res);
        },
      );
    }
  };

  return (
    <SchemaActionDefinitionContext.Provider
      value={{
        definition,
        parsedDefinition,
        schema,
        schemaAction,
        JSONInvalid,
        setSelectedTab,
        setDefinition,
        isSavingDefinition,
        builderContainerRef,
      }}
    >
      <Drawer
        title={schemaAction?.name}
        isOpen={!!schemaAction}
        onClose={closeDrawer}
        size={isMobile ? '95%' : '90%'}
        canEscapeKeyClose={false}
        className="schemaActionConfigurationDrawerSection"
      >
        <SchemaActionHelpDrawer isOpen={isHelpDrawerOpen} onClose={closeHelpDrawer} />
        <Section
          style={{ height: 'calc(100vh - 40px)' }}
          rightElement={
            <>
              <Button
                style={{ marginLeft: 10 }}
                icon="help"
                text="Help"
                onClick={() => setIsHelpDrawerOpen(true)}
              />

              <Button
                intent={JSONInvalid ? 'danger' : 'primary'}
                icon="floppy-disk"
                disabled={JSONInvalid || isSavingDefinition}
                loading={isSavingDefinition}
                onClick={saveDefinition}
                text={JSONInvalid ? 'Invalid JSON' : 'Save'}
              />
            </>
          }
          title={
            <Row justify="center">
              <Col span={24} style={{ textAlign: 'center' }}>
                <Tabs
                  id="Tabs"
                  selectedTabId={selectedTab}
                  onChange={(value: any) => setSelectedTab(value)}
                >
                  <Tab id="BUILDER" title="Builder" disabled={!schemaAction?.isStepFlow} />
                  <Tab id="DEFINITION" title="Definition" />
                  <Tab id="PERMISSIONS" title="Permissions" />
                </Tabs>
              </Col>
            </Row>
          }
        >
          {/* Builder */}
          <div style={{ display: selectedTab === 'BUILDER' ? 'block' : 'none' }}>
            <SchemaActionBuilder />
          </div>

          {/* JSON Definition */}
          <div style={{ display: selectedTab === 'DEFINITION' ? 'block' : 'none' }}>
            <SchemaActionJSONDefinition />
          </div>

          {/* Permissions */}
          <div style={{ display: selectedTab === 'PERMISSIONS' ? 'block' : 'none' }}>
            <SchemaActionPermissions />
          </div>
        </Section>
      </Drawer>
    </SchemaActionDefinitionContext.Provider>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchemaActionConfiguration);
