/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import { Html5QrcodeScanner } from 'html5-qrcode';
import { QrDimensions } from 'html5-qrcode/esm/core';
import { useEffect } from 'react';

const qrcodeRegionId = "html5qr-code-full-region";

interface CreateConfigProps {
    fps: number;
    qrbox: QrDimensions;
    aspectRatio: boolean;
    disableFlip: React.Dispatch<React.SetStateAction<string>>;
}

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = ({
    fps,
    qrbox,
    aspectRatio,
    disableFlip,
}: CreateConfigProps) => {
    let config: any = {};

    if (fps) {
        config.fps = fps;
    }
    if (qrbox) {
        config.qrbox = qrbox;
    }
    if (aspectRatio) {
        config.aspectRatio = aspectRatio;
    }
    if (disableFlip !== undefined) {
        config.disableFlip = disableFlip;
    }
    return config;
};

const Html5QrcodePlugin = (props: any) => {

    useEffect(() => {
        // when component mounts
        const config = createConfig(props);
        const verbose = false;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, {
            ...config, 
            aspectRatio: 3.0,
            showTorchButtonIfSupported: true,
            showZoomSliderIfSupported: true,
        }, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    return (
        <div style={{
            padding: '100px'
        }} id={qrcodeRegionId} />
    );
};

export default Html5QrcodePlugin;
