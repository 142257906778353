import { Button, MenuItem } from '@blueprintjs/core';
import { BlueprintIcons_16 } from '@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { httpGet } from '@core/http/requests';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

interface Props {
  schemaReducer: ISchemaReducer;
  selectedSchemaActionName?: string;
  onSelect: (newValue: string, oldValue: string | undefined) => void;
  onChange?: (value: string) => void;
}

interface ISchemaActionItem {
  key: string;
  name: string;
  value: string;
  icon?: string;
  label?: string;
  disabled?: boolean;
}

const SchemaActionNameSearch: React.FC<Props> = (props: Props) => {
  const { onSelect, schemaReducer, selectedSchemaActionName, onChange } = props;

  const [schemaActionList, setSchemaActionList] = useState<SchemaActionEntity[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isSearchingSchemaActions, setIsSearchingSchemaActions] = useState<boolean>(false);

  useEffect(() => {
    getAllSchemaActions();
  }, []);

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const getAllSchemaActions = async () => {
    try {
      setIsSearchingSchemaActions(true);

      const res = await httpGet(`SchemaModule/v1.0/schemas-actions/`);
      setIsSearchingSchemaActions(false);
      const actions: any[] = res.data.data || [];
      setSchemaActionList(actions);

      // console.log('🛠️ debug: SCHEMA ACTIONS', actions);
    } catch (error) {
      console.error('Error getting schema actions', error);
      setIsSearchingSchemaActions(false);
    }
  };

  const handleItemSelect = (item: ISchemaActionItem) => {
    const newSchemaAction = schemaActionList.find((schemaAction) => schemaAction.id === item.key);
    if (newSchemaAction) {
      onSelect(newSchemaAction?.name, selectedSchemaActionName || undefined);
    }
  };

  const renderSchemaActionItems: ItemRenderer<ISchemaActionItem> = (
    entity,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={selectedSchemaActionName === entity.name}
          disabled={entity.disabled}
          key={entity.key}
          icon={(entity.icon as BlueprintIcons_16) || null}
          onClick={handleClick}
          label={entity.label}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={entity.name}
        />
      );
  };

  let SCHEMA_ACTIONS: ISchemaActionItem[] = [];

  schemaActionList.forEach((schemaAction) => {
    const schema = schemaReducer.list?.find((schema: any) => schema.id === schemaAction.schemaId);

    SCHEMA_ACTIONS.push({
      key: schemaAction.id,
      name: schemaAction.name,
      value: schemaAction.name,
      label: schema?.entityName || undefined,
      disabled: false,
    });
  });

  SCHEMA_ACTIONS.sort((a: ISchemaActionItem, b: ISchemaActionItem) => {
    return a.name.localeCompare(b.name);
  });

  // Filter schema names by search query
  if (searchQuery.length > 0) {
    SCHEMA_ACTIONS = SCHEMA_ACTIONS.filter((entity) =>
      entity.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }

  // Don't show the entire list...
  if (searchQuery.length === 0) {
    SCHEMA_ACTIONS = SCHEMA_ACTIONS.slice(0, 100);
  }

  //  If there's no search query, ADD a "Use search to find more results" option to the bottom
  if (searchQuery.length === 0) {
    SCHEMA_ACTIONS.push({
      key: 'none',
      name: 'Use filter on top to find more Actions',
      value: '',
      icon: 'search',
      disabled: true,
    });
  }

  return (
    <Select<ISchemaActionItem>
      items={SCHEMA_ACTIONS}
      fill
      activeItem={SCHEMA_ACTIONS.find((item) => item.name === selectedSchemaActionName)}
      resetOnClose
      disabled={schemaActionList.length === 0}
      itemRenderer={renderSchemaActionItems}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      scrollToActiveItem
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        fill={true}
        outlined={!!selectedSchemaActionName}
        alignText="left"
        icon={selectedSchemaActionName ? 'form' : undefined}
        rightIcon="caret-down"
        text={selectedSchemaActionName || 'Select Schema Action'}
        loading={isSearchingSchemaActions}
        disabled={schemaActionList.length === 0}
      />
    </Select>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SchemaActionNameSearch);
