import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { Badge } from 'antd';
import State from 'ol/source/State';
import React from 'react';
import { connect } from 'react-redux';
import RecordStageChangeDialog from '../SchemaActions/RecordStageChangeDialog';
import './styles.scss';

interface Props {
  className?: string;
  flat?: boolean;
  onSuccess?: Function;
  record: DbRecordEntityTransform;
  small?: boolean;
  stageKey?: string;
  styles?: React.CSSProperties;
  pipeline: PipelineEntity | undefined;
}

interface State {
  pipeline: PipelineEntity | undefined;
  hoveredStage: string | undefined;
  stageKey: string | undefined;
}

// V2 Pipeline component uses pipeline passed as an external prop. We use this
// component on record detail views, since there we pass pipelines along with record and schema.
class RecordStageStandardPipeline extends React.Component<Props, State> {
  private pipelineContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      pipeline: undefined,
      hoveredStage: undefined,
      stageKey: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pipeline != this.props.pipeline) {
      this.setState({ pipeline: this.props.pipeline });
    }
  }

  private getPipelineState(elem: PipelineStageEntity) {
    const { record } = this.props;
    if (record && record.stage?.id === elem?.id && !elem.isFail) {
      return 'active';
    } else if (record && record.stage?.id === elem?.id && elem.isFail) {
      return 'failed';
    } else if (record && record.stage && record.stage.position > elem.position) {
      return 'complete';
    } else {
      return 'inactive';
    }
  }

  // Render Badge or Icon in the mobile Pipeline Select component
  renderBadgeOrIconInSelectBox = (elem: any) => {
    const status = this.getPipelineState(elem);

    switch (status) {
      case 'active':
        return <Badge color="blue" className="compactPipelineBadge" />;
      case 'failed':
        return <CloseOutlined style={{ color: 'red', marginRight: 5 }} />;
      case 'complete':
        return <CheckOutlined style={{ color: 'green', marginRight: 5 }} />;
      case 'inactive':
        return <Badge color="grey" className="compactPipelineBadge" />;
      default:
        return <Badge color="grey" className="compactPipelineBadge" />;
    }
  };

  renderStageIntent = (stage: PipelineStageEntity) => {
    if (stage.isFail) {
      return 'danger';
    } else if (stage.isSuccess) {
      return 'success';
    } else {
      return 'primary';
    }
  };

  // Stage Change Actions - get all stages from a pipeline, and render them as a dropdown menu
  pipelineStageActions = (): JSX.Element[] => {
    const { record } = this.props;
    const { pipeline } = this.state;

    if (pipeline && pipeline?.stages?.length! > 0) {
      const currentStage = pipeline?.stages?.find(
        (elem: PipelineStageEntity) => elem.key === record?.stage?.key,
      );

      let stages: PipelineStageEntity[] = [];

      // Add current and allowed next stages to the list of stages
      if (currentStage?.allowedNextStages) {
        stages.push(currentStage, ...currentStage?.allowedNextStages);
      }

      stages = stages.sort((a: any, b: any) => a.position - b.position);

      return (
        stages?.map((elem: PipelineStageEntity, index: number) => (
          <MenuItem
            key={index}
            intent={this.renderStageIntent(elem)}
            text={elem.name}
            onClick={() => this.setState({ stageKey: elem.key })}
            disabled={elem.key === currentStage?.key}
            icon={elem.key === currentStage?.key ? <CheckOutlined /> : undefined}
          />
        )) || []
      );
    } else {
      return [];
    }
  };

  render() {
    const { record, onSuccess } = this.props;
    const { pipeline } = this.state;

    const currentStage = pipeline?.stages?.find(
      (elem: PipelineStageEntity) => elem.key === record?.stage?.key,
    );

    return (
      <>
        <RecordStageChangeDialog
          openDialog={this.state.stageKey !== undefined}
          record={record}
          targetStage={this.state.stageKey}
          onClose={() => {
            this.setState({ stageKey: undefined });
          }}
          onConfirm={() => {
            if (onSuccess) {
              onSuccess();
            }
            this.setState({ stageKey: undefined });
          }}
        />

        {pipeline && (
          <Popover content={<Menu>{this.pipelineStageActions()}</Menu>} placement="bottom">
            <Button
              alignText="left"
              text={currentStage?.name || 'Change State'}
              rightIcon="caret-down"
              disabled={pipeline?.stages?.length === 0}
            />
          </Popover>
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(RecordStageStandardPipeline);
