import {
  Alert,
  Button,
  ButtonGroup,
  InputGroup,
  Intent,
  Section,
  SectionCard,
} from '@blueprintjs/core';
import { useSelector } from 'react-redux';

import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';

import {
  deleteSchemaAssociationsRequest,
  getSchemaAssociationsRequest,
} from '@legacy/core/schemasAssociations/store/actions';
import { SchemaAssociationReducer } from '@legacy/core/schemasAssociations/store/reducer';

import { useRequest } from '@core/hooks/useRequest';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Empty } from '../Empty';
import { SchemaDetailsProps } from '../types';
import { DiagramView } from './DiagramView';
import { ListView } from './ListView';
import ManageLinkDialog from './ManageLinkDialog';

type SearchableSchemaAssociationEntity = SchemaAssociationEntity & { SearchKey: string } & {
  SearchKey: string;
};

export const LinksSection = ({ schema }: SchemaDetailsProps) => {
  const [viewMode, setViewMode] = useState<'list' | 'diagram'>('diagram');
  const [search, setSearch] = useState('');
  const [manageLinks, setManageLinks] = useState(false);
  const [linkToRemove, setLinkToRemove] = useState<SchemaAssociationEntity>();
  const [selectedLink, setSelectedLink] = useState<SchemaAssociationEntity>();
  const schemaAssociations = useSelector<any, SchemaAssociationReducer>(
    (state: any) => state.schemaAssociationReducer,
  );

  const getSchemaAssociations = useRequest(getSchemaAssociationsRequest);
  const deleteSchemaAssociations = useRequest(deleteSchemaAssociationsRequest);

  useEffect(() => {
    if (!schema?.id) return;
    getSchemaAssociations({ schemaId: schema.id });
  }, [schema?.id]);

  const { isRequesting, childAssociations, parentAssociations } = schemaAssociations;
  const selfAssociationFromChild = childAssociations.filter(
    (a) => a.parentSchemaId === a.childSchemaId,
  );
  const selfAssociationFromParent = parentAssociations.filter(
    (a) => a.parentSchemaId === a.childSchemaId,
  );

  const associations = useMemo(() => {
    const result: SearchableSchemaAssociationEntity[] = [];
    const processedAssociationIds: string[] = [];

    childAssociations.forEach((childAssociation) => {
      if (processedAssociationIds.includes(childAssociation.id)) return;

      processedAssociationIds.push(childAssociation.id);
      result.push({
        ...childAssociation,
        SearchKey: childAssociation.childSchema?.entityName || '',
      });
    });

    parentAssociations.forEach((parentAssociation) => {
      if (processedAssociationIds.includes(parentAssociation.id)) return;

      processedAssociationIds.push(parentAssociation.id);
      result.push({
        ...parentAssociation,
        SearchKey: parentAssociation.parentSchema?.entityName || '',
      });
    });

    result.sort((a, b) => a.SearchKey.localeCompare(b.SearchKey));
    return result;
  }, [childAssociations, parentAssociations]);

  const filteredAssociations = associations.filter((association) => {
    const searchText = search.toLocaleLowerCase();
    const labelFilter = association.label.toLocaleLowerCase().includes(searchText);
    const searchKeyFilter = association.SearchKey.toLocaleLowerCase().includes(searchText);

    return labelFilter || searchKeyFilter;
  });

  const showLinkDialog = (link?: SchemaAssociationEntity) => {
    setSelectedLink(link);
    setManageLinks(true);
  };

  const onRemoveLink = (link: SchemaAssociationEntity) => {
    // console.log('[DebugX] onRemoveLink', link);
    setLinkToRemove(link);
  };

  const removeLink = useCallback(async () => {
    if (!schema || !linkToRemove) return;

    await deleteSchemaAssociations({
      schemaId: schema.id,
      associationId: linkToRemove.id,
    });

    setLinkToRemove(undefined);
  }, [schema?.id, linkToRemove?.id]);

  return (
    <>
      <Section
        className="schema-links"
        title="Links"
        rightElement={
          <>
            {/* Search Links */}
            <InputGroup
              round
              type="search"
              id="schema-properties"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            {/* List View Option */}

            <ButtonGroup>
              <Button
                icon="list"
                outlined
                intent={viewMode === 'list' ? 'primary' : 'none'}
                onClick={() => setViewMode('list')}
              />

              {/* Diagram View Option */}
              <Button
                icon="many-to-one"
                outlined
                intent={viewMode === 'diagram' ? 'primary' : 'none'}
                onClick={() => setViewMode('diagram')}
              />
            </ButtonGroup>

            {/* New Link Option */}
            <Button
              outlined
              icon="plus"
              intent="success"
              text="Add"
              onClick={() => showLinkDialog()}
              style={{ borderRadius: 5 }}
            />
          </>
        }
      >
        <SectionCard padded={false} className="limited-height" style={{ overflowY: 'auto' }}>
          {isRequesting && <></>}
          {associations.length === 0 ? (
            <Empty icon="search" text="No links" />
          ) : viewMode === 'list' ? (
            <ListView
              links={filteredAssociations}
              onLinkClick={showLinkDialog}
              onLinkRemove={onRemoveLink}
              schema={schema}
            />
          ) : (
            <DiagramView
              schema={schema}
              links={filteredAssociations}
              onLinkClick={showLinkDialog}
              onLinkRemove={onRemoveLink}
            />
          )}
        </SectionCard>
      </Section>
      {manageLinks && (
        <ManageLinkDialog
          schema={schema}
          link={selectedLink}
          isOpen={manageLinks}
          onClose={() => setManageLinks(false)}
        />
      )}
      {!!linkToRemove && (
        <Alert
          isOpen={true}
          canEscapeKeyCancel
          canOutsideClickCancel
          intent={Intent.DANGER}
          loading={isRequesting}
          icon="trash"
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          onClose={() => setLinkToRemove(undefined)}
          onConfirm={removeLink}
        >
          <h3 style={{ marginTop: 0, marginBottom: '1rem' }}>Remove Link</h3>
          <p>Are you sure you want to remove this link?</p>
        </Alert>
      )}
    </>
  );
};
