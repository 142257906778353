import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import { connect } from 'react-redux';
import { Gantt, GanttConfigOptions, GanttStatic } from '@dhx/trial-gantt';
import '@dhx/trial-gantt/codebase/dhtmlxgantt.css';
import { httpGet } from '@core/http/requests';
import { getHostName } from '@core/http/helpers';
import { SET_GANTT_INSTANCE } from './store/reducer';
import { errorNotification } from '@legacy/core/notifications/store/reducers';
import { Alert } from '@blueprintjs/core';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  config?: GanttConfigOptions;
  dataProcessorUrl?: string | undefined;
  setGanttInstance: (gantt: GanttStatic) => void;
  ganttInstance: GanttStatic | null;
  notifyError: (params: any) => void;
}

interface State {
  isDeleteLinkAlertOpen: boolean;
  selectedLinkId: string | null;
  LinkFromTaskName: string;
  LinkToTaskName: string;
}

class GanttChart extends React.Component<Props, State> {
  private ganttRef = React.createRef<HTMLDivElement>();

  state = {
    isDeleteLinkAlertOpen: false,
    selectedLinkId: null,
    LinkFromTaskName: '',
    LinkToTaskName: '',
  };

  componentDidMount() {
    if (this.ganttRef.current) {
      const gantt = Gantt.getGanttInstance();
      this.configureGantt(gantt);
      gantt.init(this.ganttRef.current);
    }
  }

  private configureGantt(gantt: GanttStatic) {
    gantt.plugins({ marker: true, tooltip: true, critical_path: true, export_api: true });

    gantt.config.highlight_critical_path = false;
    gantt.config.show_slack = false;

    gantt.config.baselines = {
      datastore: 'baselines',
      render_mode: false,
      dataprocessor_baselines: false,
      row_height: 16,
      bar_height: 8,
    };

    // Set XML date format to handle ISO dates correctly. Avoids us having to convert the dates in the backend.
    gantt.config.xml_date = "%Y-%m-%d %H:%i";
    // Set date format so PDF exports work as intended
    gantt.config.date_format = "%Y-%m-%d %H:%i";

    // Add today red line marker
    gantt.addMarker({
      start_date: new Date(),
      css: 'today-line',
      title: new Date().toDateString(),
    });

    gantt.config.scale_unit = 'month';
    gantt.config.date_scale = '%F, %Y';
    gantt.config.scale_height = 50;
    gantt.config.subscales = [
      { unit: 'day', step: 1, date: '%j, %D' }
    ];

    // Override default delete link confirmation modal with BlueprintJS Alert
    gantt.attachEvent('onLinkDblClick', (id) => {
      const link = gantt.getLink(id);

      this.setState({
        isDeleteLinkAlertOpen: true,
        selectedLinkId: id.toString(),
        LinkFromTaskName: gantt.getTask(link.source).text,
        LinkToTaskName: gantt.getTask(link.target).text
      });

      return false; // Prevent default behavior
    });

    // Override config if provided
    if (this.props.config) {
      // Apply config properties to gantt instance, ensuring functions are correctly assigned
      Object.entries(this.props.config).forEach(([key, value]) => {
        if (key === 'locale') {
          // Merge locale settings instead of overwriting
          gantt.locale.labels = { ...gantt.locale.labels, ...value.labels };
        } else if (key === 'columns') {
          gantt.config.columns = value;
        } else {
          gantt[key] = value;
        }
      });
    }

    gantt.templates.task_class = function(start, end, task) {
      var css = [];

      switch (task.progress) {
        case 1:
          css.push('complete');
          break;
        case 0:
          css.push('not_started');
          break;
        default:
          css.push('in_progress');
          break;
      }

      return css.join(' ');
    };

    gantt.templates.progress_text = function (start, end, task) {
      let progress = task.progress != null ? Math.floor(task?.progress * 100) : 0
      return `<span>${progress}%</span>`;
    };

    if (this.props.dataProcessorUrl) {
      const token = localStorage.getItem(`token`);

      // Add flash messages for API errors
      gantt.attachEvent('onAjaxError', (request: any) => {
        const response = request.response ? JSON.parse(request.response) : null;

        if (!response?.successful) {
          this.props.notifyError({
            message: response?.message || `HTTP error ${request.status}: Failed to process request`
          });
        }

        return true;
      });

      // Common function for all API calls
      const makeRequest = (method: string, entity: string, data?: any, id?: string | number) => {
        const url = `${getHostName()}/${this.props.dataProcessorUrl}/${entity}${id ? `/${id}` : ''}`;

        return gantt.ajax[method]({
          url,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data: data ? JSON.stringify(data) : undefined
        }).then((response: XMLHttpRequest) => {
          const parsedData = JSON.parse(response.response);
          return parsedData.data;
        })
      };

      // Create the data processor utilising our function for API calls, ensuring responses are handled correctly
      const dp = gantt.createDataProcessor({
        task: {
          create: (data) => makeRequest('post', 'task', data),
          update: (data, id) => makeRequest('put', 'task', data, id),
          delete: (id) => makeRequest('del', 'task', undefined, id)
        },
        link: {
          create: (data) => makeRequest('post', 'link', data),
          update: (data, id) => makeRequest('put', 'link', data, id),
          delete: (id) => makeRequest('del', 'link', undefined, id)
        }
      });

      // Load the initial tasks and links
      httpGet(this.props.dataProcessorUrl).then((response) => {
        gantt.parse(response.data.data);
        this.props.setGanttInstance(gantt);
      });

      // set the row heights to allow room for the toggleable baseline dates
      gantt.attachEvent("onBeforeTaskDisplay", (id: any, task: any) => {
        task.bar_height = 30
        task.row_height = 30 + 16 // task.bar_height + gantt.config.baselines.row_height
        return true
      });
    }
  }

  handleDeleteLink = () => {
    const { ganttInstance } = this.props;
    const { selectedLinkId } = this.state;

    if (selectedLinkId && ganttInstance) {
      ganttInstance.deleteLink(selectedLinkId);
    }

    this.setState({
      isDeleteLinkAlertOpen: false,
      selectedLinkId: null,
      LinkFromTaskName: '',
      LinkToTaskName: ''
    });
  };

  render() {
    const { isDeleteLinkAlertOpen, LinkFromTaskName, LinkToTaskName } = this.state;

    return (
      <>
        <div
          ref={this.ganttRef}
          style={{
            width: '100%',
            // Subtract height of header, navigation and padding (190px) to fill remaining viewport
            height: 'calc(100vh - 190px)'
          }}
        />
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          intent="danger"
          isOpen={isDeleteLinkAlertOpen}
          onCancel={() => this.setState({ isDeleteLinkAlertOpen: false })}
          onConfirm={this.handleDeleteLink}
        >
          <p>Are you sure you want to delete the link between <b>{LinkFromTaskName}</b> and <b>{LinkToTaskName}</b>? This action cannot be undone.</p>
        </Alert>
      </>
    );
  }
}

const mapState = (state: any) => ({
  ganttInstance: state.ganttReducer.ganttInstance
});

const mapDispatch = (dispatch: any) => ({
  setGanttInstance: (gantt: GanttStatic) => dispatch({ type: SET_GANTT_INSTANCE, payload: gantt }),
  notifyError: (params: any) => dispatch(errorNotification(params)),
});

export default connect(mapState, mapDispatch)(GanttChart);
