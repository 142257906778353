import React from 'react';
import { Row, Col, Spin } from 'antd';
import { Kanban, template } from '@dhx/trial-kanban';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import ReactDOMServer from 'react-dom/server';

import '@dhx/trial-kanban/dist/kanban.css';
import './styles.scss';
import BoardCard, { BoardCardProps } from './BoardCard';
import BoardCardDrawer from './BoardCardDrawer';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

interface KanbanBoardProps {
  loading: boolean;
  records: DbRecordEntityTransform[];
  stagesList: string[];
  schema: SchemaEntity;
}

interface KanbanBoardState {
  customDrawerVisible: boolean;
  selectedRecord: DbRecordEntityTransform | null;
}

class KanbanBoard extends React.Component<KanbanBoardProps, KanbanBoardState> {
  private kanbanRef = React.createRef<HTMLDivElement>();
  private kanban: Kanban | null = null;

  constructor(props: KanbanBoardProps) {
    super(props);
    this.state = {
      customDrawerVisible: false,
      selectedRecord: null,
    };
  }

  componentDidMount() {
    if (this.kanbanRef.current) {
      this.initKanban();
    }
  }

  componentDidUpdate(prevProps: KanbanBoardProps) {
    if (prevProps.records !== this.props.records) {
      if (this.kanban) {
        this.kanban.destructor();
      }
      this.initKanban();
    }
  }

  componentWillUnmount() {
    this.kanban?.destructor();
  }

  private initKanban() {
    this.kanban = new Kanban(this.kanbanRef.current!, {
      columns: this.props.stagesList.map((stage) => ({ id: stage, label: stage })),
      cards: this.props.records.map((record) => ({
        id: record.id,
        label: [record.properties.JiraProjectKey, record.title].filter(Boolean).join(' - '),
        type: record.type,
        description: record.properties.Description,
        stage: record.stage?.name,
        recordNumber: record.recordNumber,
        createdAt: record.createdAt,
        column: record.stage?.name,
      })),
      cardTemplate: template((card: BoardCardProps) => ReactDOMServer.renderToString(<BoardCard {...card} />)),
      readonly: {
        edit: false,
        add: false,
        select: true,
        dnd: false,
      }
    });

    this.kanban.api.on('select-card', (obj) => {
      const record = this.props.records.find(r => r.id === obj.id);

      if (record) {
        this.setState({
          customDrawerVisible: true,
          selectedRecord: record,
        });
      }
    });
  }

  render() {
    const { loading } = this.props;
    const { customDrawerVisible } = this.state;

    if (loading) {
      return (
        <Row style={{ height: '97vh', paddingTop: '12vh' }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Spin style={{ marginBottom: 20 }} /><br /><span>Loading DataSet records...</span>
          </Col>
        </Row>
      );
    }

    return (
      <>
        <div
          ref={this.kanbanRef}
          style={{
            width: '100%',
            // Subtract height of header, navigation and padding (135px) to fill remaining viewport
            height: 'calc(100vh - 135px)',
          }}
        />
        {this.state.selectedRecord && this.props.schema && (
          <BoardCardDrawer
            visible={customDrawerVisible}
            onClose={() => this.setState({ customDrawerVisible: false })}
            record={this.state.selectedRecord}
            schema={this.props.schema}
          />
        )}
      </>
    );
  }
}

export default KanbanBoard;
