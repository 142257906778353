import { Button, Dialog, DialogFooter } from '@blueprintjs/core';

interface Props {
  isModalVisible: boolean;
  togglePDFModal: any;
  file: any;
}

const PDFModalViewer = (props: Props) => {
  const { isModalVisible, file, togglePDFModal } = props;

  return (
    <Dialog
      title="PDF Preview"
      style={{ width: '80%', height: '100%' }}
      isCloseButtonShown={true}
      isOpen={isModalVisible}
      onClose={togglePDFModal}
    >
      {isModalVisible && (
        <object style={{ minHeight: 'calc(100vh - 200px)' }} data={file} type="application/pdf" />
      )}

      <DialogFooter actions={[<Button text="Close" onClick={togglePDFModal} />]} />
    </Dialog>
  );
};

export default PDFModalViewer;
