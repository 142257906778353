import { InputGroup, Section } from '@blueprintjs/core';
import BlueprintBadge from '@core/components/BlueprintBadge';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { httpGet, httpPost } from '@core/http/requests';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { Col, Row } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import MyCasesBottomRowControl from './components/MyCasesBottomRowControl';
import MyCasesConversationFeed from './components/MyCasesConversationFeed';
import MyCasesHeader from './components/MyCasesHeader';
import MyCasesList from './components/MyCasesList';
import MyCasesSingleCaseSidebar from './components/MyCasesSingleCaseSidebar';
import MyCasesWebSockets from './components/MyCasesWebSockets';
import {
  MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES,
  MY_CASES_RESET_CURRENT_CASE_COUNT,
  MY_CASES_SET_ASSOCIATED_ACCOUNTS,
  MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT,
  MY_CASES_SET_CASE_UNREAD_STATUS,
  MY_CASES_SET_CASES,
  MY_CASES_SET_CONTACT,
  MY_CASES_SET_IS_LOADING_ACTIVITY,
  MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE,
  MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT,
  MY_CASES_SET_MY_CASES_SEARCH_QUERY,
  MY_CASES_SET_SELECTED_ACCOUNT,
  MY_CASES_SET_SELECTED_CASE,
  MY_CASES_SET_SELECTED_CASE_CALLS,
  MY_CASES_SET_SELECTED_CASE_CONVERSATION,
  MY_CASES_SET_SELECTED_CASE_EMAILS,
  MY_CASES_SET_SELECTED_CASE_MESSAGES,
  MY_CASES_SET_SELECTED_CASE_NOTES,
  MY_CASES_SET_SINGLE_CASE_MODE,
  MY_CASES_TOGGLE_EMAIL_EDITOR,
  MY_CASES_TOGGLE_MESSAGE_EDITOR,
  MY_CASES_TOGGLE_NOTE_EDITOR,
} from './store/constants';
import { IMyCasesReducer, myCasesReducer, MyCasesReducerInitialState } from './store/reducer';
import './styles.scss';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, ACCOUNT } = SchemaModuleEntityTypeEnums;
const CASE = 'Case';

interface Props {
  userReducer: any;
  // Pass one case in props preselect a case and switch to single case mode
  defaultCase?: DbRecordEntityTransform;
  alertMessage: (params: { body: string; type: string }) => void;
}

export type TMyCasesContext = {
  state: IMyCasesReducer;
  dispatch: React.Dispatch<any>;
};

export const MyCasesContext = React.createContext<TMyCasesContext>({
  state: MyCasesReducerInitialState,
  dispatch: () => {},
});

const MyCasesViewV2: React.FC<Props> = (props: Props) => {
  const { userReducer, defaultCase, alertMessage } = props;
  const [state, dispatch] = useReducer(myCasesReducer, MyCasesReducerInitialState);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);
  const selectedCase = state.selectedCase;

  // On component mount, fetch Case schema and all associated Cases
  useEffect(() => {
    getCaseSchema();

    if (!defaultCase) {
      getCaseList();
    }
  }, []);

  // Preselect the default case if passed in props, and toggle single case mode
  useEffect(() => {
    if (defaultCase) {
      dispatch({ type: MY_CASES_SET_SELECTED_CASE, payload: defaultCase });
      dispatch({ type: MY_CASES_SET_SINGLE_CASE_MODE, payload: true });
    }
  }, [defaultCase]);

  // If case is selected, fetch activity and associated contact
  useEffect(() => {
    if (selectedCase) {
      getCaseActivity(selectedCase?.id);
      getAssociatedContactForCase(selectedCase?.id);
      getAssociatedConversationForCase(selectedCase?.id);

      if (state.isComposingEmail) {
        dispatch({ type: MY_CASES_TOGGLE_EMAIL_EDITOR, payload: false });
      }
      if (state.isComposingMessage) {
        dispatch({ type: MY_CASES_TOGGLE_MESSAGE_EDITOR, payload: false });
      }
      if (state.isAddingNote) {
        dispatch({ type: MY_CASES_TOGGLE_NOTE_EDITOR, payload: false });
      }
    }
  }, [state.selectedCase?.id]);

  // If contact is selected, fetch associated Accounts and Cases for that contact
  useEffect(() => {
    if (state.selectedCaseContact) {
      getAssociatedAccountsForContact(state.selectedCaseContact?.id);
      getCasesAssociatedWithContact(state.selectedCaseContact?.id);
    }
  }, [state.selectedCaseContact?.id]);

  // Attach scroll event listener to feed, check if user has scrolled up and
  // show scroll down button if so. Reset current case count if user is at the bottom.
  useEffect(() => {
    const handleScroll = () => {
      if (state.feedContainerRef?.current) {
        const { scrollTop } = state.feedContainerRef.current;
        const roundedScrollTop = Math.floor(scrollTop);
        const isScrolledUp = roundedScrollTop < 0; // Adjust the threshold as needed

        // User scrolled up
        if (isScrolledUp) {
          dispatch({ type: MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE, payload: true });
        }
        // User is at the bottom of the feed
        else {
          dispatch({ type: MY_CASES_RESET_CURRENT_CASE_COUNT, payload: true });
          dispatch({ type: MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE, payload: false });
          dispatch({
            type: MY_CASES_SET_CASE_UNREAD_STATUS,
            payload: { caseId: state.selectedCase?.id, status: 'READ' },
          });
        }
      }
    };

    const currentFeedContainer = state.feedContainerRef?.current;
    if (currentFeedContainer) {
      currentFeedContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentFeedContainer) {
        currentFeedContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [state.feedContainerRef, state.selectedCase?.id]);

  const getCaseSchema = async () => {
    const schema = await getOdinSchemaByEntity('SupportModule', 'Case');
    if (schema) {
      setCaseSchema(schema);
    }
  };

  const getCaseList = async () => {
    const query = {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'OwnerId',
            operator: 'eq',
            value: userReducer?.user?.id,
          },
          {
            columnName: 'StageName',
            operator: 'not in',
            value: ['Solved', 'Closed'],
          },
        ],
        returnProperties: [
          'id',
          'title',
          'recordNumber',
          'createdAt',
          'stage',
          'schemaId',
          'type',
          'groups',
          'entity',
          'caseTeam',
          'caseOwner',
          'caseContact',
          'properties.OwnerId',
          'properties.TeamId',
          'properties.ContactId',
          'properties.Category',
          'properties.Source',
          'properties.Channel',
        ],
        sort: {
          createdAt: {
            order: 'desc',
          },
        },
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 1000,
      },
    };
    try {
      await httpPost(`SupportModule/v2.0/records/search`, query).then((res) => {
        const records = res?.data?.data?.records || [];
        // console.log('%cdebug: Search Results', 'color:limegreen', records);
        dispatch({ type: MY_CASES_SET_CASES, payload: records });
      });
    } catch (error: any) {
      alertMessage({ body: error.response?.data?.message || error.message, type: 'error' });
    }
  };

  const getAssociatedContactForCase = async (caseId: string) => {
    try {
      dispatch({ type: MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT, payload: true });
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CASE}/${caseId}/one-relation?entity=${CONTACT}&withLinks=false`,
      );
      const contact = res?.data[CONTACT]?.dbRecords[0] || undefined;
      dispatch({ type: MY_CASES_SET_CONTACT, payload: contact });
      dispatch({ type: MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT, payload: false });
    } catch (error) {
      dispatch({ type: MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT, payload: false });
      dispatch({ type: MY_CASES_SET_CONTACT, payload: undefined });
    }
  };

  const getAssociatedAccountsForContact = async (contactId: string) => {
    try {
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CONTACT}/${contactId}/one-relation?entity=${ACCOUNT}&withLinks=false`,
      );
      const accounts = res?.data[ACCOUNT]?.dbRecords || [];
      dispatch({ type: MY_CASES_SET_ASSOCIATED_ACCOUNTS, payload: accounts });

      // Set the first account as selected account
      if (accounts.length > 0) {
        dispatch({ type: MY_CASES_SET_SELECTED_ACCOUNT, payload: accounts[0] });
      }
    } catch (error) {}
  };

  const getAssociatedConversationForCase = async (caseId: string) => {
    try {
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CASE}/${caseId}/one-relation?entity=Conversation&withLinks=false`,
      );
      const conversation = res?.data['Conversation']?.dbRecords[0] || undefined;
      dispatch({ type: MY_CASES_SET_SELECTED_CASE_CONVERSATION, payload: conversation });
    } catch (error) {
      dispatch({ type: MY_CASES_SET_SELECTED_CASE_CONVERSATION, payload: undefined });
    }
  };

  const getCasesAssociatedWithContact = async (contactId: string) => {
    try {
      dispatch({ type: MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES, payload: true });
      const res = await httpGet(
        `${SUPPORT_MODULE}/v1.0/db-associations/${CONTACT}/${contactId}/one-relation?entity=${CASE}&withLinks=false`,
      );
      const cases = res?.data[CASE]?.dbRecords || [];

      dispatch({ type: MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT, payload: cases });
      dispatch({ type: MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES, payload: false });
    } catch (error) {
      dispatch({ type: MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES, payload: false });
    }
  };

  const getCaseActivity = async (caseId: string) => {
    try {
      dispatch({ type: MY_CASES_SET_IS_LOADING_ACTIVITY, payload: true });

      const caseRes = await httpGet(`SupportModule/v1.0/case/${caseId}/activity`);

      dispatch({ type: MY_CASES_SET_IS_LOADING_ACTIVITY, payload: false });

      if (caseRes.data) {
        const messages: DbRecordEntityTransform[] = caseRes.data?.data?.data?.messages || [];
        const notes: DbRecordEntityTransform[] = caseRes.data?.data?.data?.notes || [];
        const emails = caseRes.data?.data?.data?.emails || [];
        const calls = caseRes.data?.data?.data?.calls || [];

        console.log('%cdebug: Selected case / activity', 'color:salmon', {
          case: state.selectedCase,
          activity: caseRes.data?.data?.data,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_MESSAGES,
          payload: messages,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_NOTES,
          payload: notes,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_EMAILS,
          payload: emails,
        });

        dispatch({
          type: MY_CASES_SET_SELECTED_CASE_CALLS,
          payload: calls,
        });

        setTimeout(() => {
          const feedContainer = state.feedContainerRef.current;
          if (feedContainer) {
            feedContainer.scrollTop = feedContainer.scrollHeight;
          }
        }, 100);
      }
    } catch (error: any) {
      alertMessage({ body: error.response?.data?.message || error.message, type: 'error' });
      dispatch({ type: MY_CASES_SET_IS_LOADING_ACTIVITY, payload: false });
    }
  };

  return (
    <>
      <MyCasesContext.Provider value={{ state, dispatch }}>
        <MyCasesWebSockets />
        <Row className={`myCasesContainer ${state.isSingleCaseMode ? 'singleCase' : ''}`}>
          {/* Left Column, hide if there's a default case passed */}
          {!defaultCase && (
            <Col sm={5} xxl={4} style={{ height: 'inherit', marginTop: 1 }}>
              <Row align="top">
                {/* Title and Search */}
                <Col span={24} style={{ background: 'white' }}>
                  <Section
                    title="My Cases"
                    className="titleSection"
                    rightElement={<BlueprintBadge number={state.cases.length} bold />}
                  >
                    <div style={{ padding: '10px 15px' }}>
                      <Row justify="space-between">
                        <Col span={24}>
                          {/* My Cases Tab */}
                          {/* <Tabs
                            id="myCasesTabs"
                            selectedTabId={state.selectedCasesTabId}
                            onChange={(id: string) => {
                              dispatch({ type: MY_CASES_SET_ACTIVE_TAB, payload: id });
                            }}
                          >
                            <Tab
                              disabled={!state.cases.length}
                              id="my-queue"
                              title={
                                <span style={{ display: 'inline-block' }}>
                                  My Queue
                                  <BlueprintBadge number={state.cases.length} bold />
                                </span>
                              }
                            />
                            <Tab
                              id="following"
                              disabled
                              title={
                                <span style={{ display: 'inline-block' }}>
                                  Following
                                  <BlueprintBadge number={0} color="#545a5d" />
                                </span>
                              }
                            />
                          </Tabs> */}
                          <InputGroup
                            type="search"
                            leftIcon="search"
                            placeholder="Search by Case # or Contact name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              dispatch({
                                type: MY_CASES_SET_MY_CASES_SEARCH_QUERY,
                                payload: e.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Section>
                </Col>
              </Row>

              {/* My Cases List */}
              <MyCasesList schema={caseSchema!} />
            </Col>
          )}

          {/* Right Column */}
          <Col sm={defaultCase ? 24 : 19} xxl={defaultCase ? 24 : 20}>
            {/* Case Header */}
            <MyCasesHeader schema={caseSchema!} />
            <div
              style={{
                height: `calc(100vh - ${state.isSingleCaseMode ? '150px' : '150px'})`,
                background: '#fff',
              }}
            >
              <Row>
                {/* Conversation Feed */}
                <MyCasesConversationFeed />

                {/* Contact Details - Multiple Case Mode */}
                {/*{!state.isSingleCaseMode && <MyCasesContactDrawer />}*/}

                {/* Contact Details - Single Case Mode */}
                {caseSchema && <MyCasesSingleCaseSidebar schema={caseSchema} />}
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{ height: 57, border: '1px solid #d9dada', background: 'white' }}
                >
                  <MyCasesBottomRowControl />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </MyCasesContext.Provider>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(MyCasesViewV2);
