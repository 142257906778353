import { Dialog, DialogBody, DialogFooter, Button, Alert } from '@blueprintjs/core';
import { Form, Input, Select, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { GanttStatic } from '@dhx/trial-gantt';
import { errorNotification } from '@legacy/core/notifications/store/reducers';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';

interface Props {
  id: string;
  ganttInstance: GanttStatic;
  notifyError: any;
  onClose: () => void;
  users: OrganizationUserEntity[];
  isLoadingUsers: boolean;
}

class TaskForm extends React.Component<Props> {
  formRef = React.createRef<FormInstance>();
  state = {
    isDeleteAlertOpen: false
  };

  handleSubmit = async () => {
    const { notifyError, ganttInstance, id, onClose } = this.props;
    try {
      if (this.formRef.current) {
        await this.formRef.current.validateFields();
        const formErrors = this.formRef.current.getFieldsError();
        const hasErrors = formErrors.filter(({ errors }) => errors.length).length > 0;

        if (hasErrors) {
          return notifyError({
            message: 'form has errors, fix them and resubmit',
            validation: null,
            data: null,
          });
        }

        const values = this.formRef.current.getFieldsValue();
        const task = ganttInstance.getTask(id);
        task.type = values.type;
        task.text = values.name;
        task.description = values.description;
        task.start_date = values.date_range[0]?.toDate();
        task.end_date = values.date_range[1]?.toDate();
        task.owner_id = values.owner_id;

        if (task.$new) {
          delete task.$new;
          ganttInstance.addTask(task);
        } else {
          ganttInstance.updateTask(id, task);
        }

        // re-render to ensure new & updated tasks are visible when tasks are moved out of the visible start and end dates range
        ganttInstance.render();

        // close the dialog
        onClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleClose = () => {
    const { ganttInstance, id, onClose } = this.props;
    const task = ganttInstance.getTask(id);
    if (task.$new) {
      ganttInstance.silent(() => {
        ganttInstance.deleteTask(id);
      });

      ganttInstance.render();
    }
    onClose();
  };

  handleDelete = () => {
    const { ganttInstance, id, onClose } = this.props;
    ganttInstance.deleteTask(id);
    onClose();
  };

  handleTypeChange = (value: string) => {
    const dateRange = this.formRef.current?.getFieldValue('date_range');

    if (dateRange) {
      if (value === 'task') {
        if (dateRange[0].isSame(dateRange[1], 'day')) {
          this.formRef.current?.setFieldsValue({ date_range: [dateRange[0], dateRange[1].add(1, 'day')] });
        }
      } else if (value === 'milestone') {
        if (!dateRange[0].isSame(dateRange[1], 'day')) {
          this.formRef.current?.setFieldsValue({ date_range: [dateRange[0], dateRange[0]] });
        }
      }
    }
  };

  render() {
    const { ganttInstance, id, users, isLoadingUsers } = this.props;
    const task = ganttInstance.getTask(id);
    const isNewTask = task.$new;

    // reset the new task dates as createTask used in the reducer does not respect start_date and end_date reset
    if (isNewTask) {
      task.start_date = undefined;
      task.end_date = undefined;
    }

    return (
      <>
        <Dialog
          isOpen={true}
          onClose={this.handleClose}
          title={isNewTask ? "Create Task" : "Edit Task"}
        >
          <DialogBody>
            <Form
              ref={this.formRef}
              layout="vertical"
              initialValues={{
                name: task.text,
                description: task.description,
                type: task.type,
                owner_id: task.owner_id,
                date_range: task.start_date && task.end_date ? [
                  dayjs(task.start_date),
                  dayjs(task.end_date)
                ] : undefined,
              }}
            >
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Please select task type' }]}
              >
                <Select onChange={this.handleTypeChange}>
                  <Select.Option value="task" key="task-type-task">
                    Task
                  </Select.Option>
                  <Select.Option value="milestone" key="task-type-milestone">
                    Milestone
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please input task name' }]}
              >
                <Input placeholder="Enter task name" />
              </Form.Item>

              <Form.Item
                name="owner_id"
                label="Owner"
              >
                <Select
                  loading={isLoadingUsers}
                  placeholder="Select task owner"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    (option?.label || '').toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {users.map((user: any) => {
                    const username = `${user.firstName} ${user.lastName}`;
                    return (
                      <Select.Option key={user.id} value={user.id} label={username}>
                        {username}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
              >
                <Input.TextArea placeholder="Enter task description" />
              </Form.Item>

              <Form.Item
                name="date_range"
                label="Date Range"
                rules={[{
                  required: true,
                  type: 'array' as const,
                  message: 'Please select start and end dates'
                }]}
              >
                <DatePicker.RangePicker
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                  onChange={() => {
                    this.handleTypeChange(this.formRef.current?.getFieldValue('type'));
                  }}
                />
              </Form.Item>
            </Form>
          </DialogBody>
          <DialogFooter
            actions={
              <>
                <Button onClick={this.handleClose}>Cancel</Button>
                <Button intent="primary" onClick={this.handleSubmit}>
                  {isNewTask ? 'Create' : 'Save'}
                </Button>
              </>
            }
          >
            {!isNewTask && (
              <Button intent="danger" onClick={() => this.setState({ isDeleteAlertOpen: true })}>
                Delete
              </Button>
            )}
          </DialogFooter>
        </Dialog>
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          intent="danger"
          isOpen={this.state.isDeleteAlertOpen}
          onCancel={() => this.setState({ isDeleteAlertOpen: false })}
          onConfirm={this.handleDelete}
        >
          <p>Are you sure you want to delete this task? This action cannot be undone.</p>
        </Alert>
      </>
    );
  }
}

const mapState = (state: any) => ({
  ganttInstance: state.ganttReducer.ganttInstance,
  users: state.ganttReducer.users || [],
  isLoadingUsers: state.ganttReducer.isLoadingUsers || false,
});

const mapDispatch = (dispatch: any) => ({
  notifyError: (params: any) => dispatch(errorNotification(params)),
});

export default connect(mapState, mapDispatch)(TaskForm);
