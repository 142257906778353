import { Section } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';

interface Props {}

const ActionBuilder: React.FC<Props> = (props: Props) => {
  const {} = props;
  return (
    <Row>
      <Col span={24}>
        <Section title="Schema Action"></Section>
      </Col>
    </Row>
  );
};
export default ActionBuilder;
