import { v4 as uuidv4 } from 'uuid';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Badge, Button, Col, Collapse, Empty, Row, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import FileByTypeAssociation from '@core/views/Files/containers/FileByTypeAssociation';
import { AssociatedFilesDisplayType } from '@core/views/Files/types';
import { canUserGetRecord, isSystemAdmin } from '@core/helpers/rbacRules';
import { getChildSchemaTypes, getSchemaAssociationByEntity } from '@core/helpers/recordHelpers';
import {
  getElasticSearchKeysFromSchemaColumn,
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';
import { formatDbRecordListColumns } from '../../../records/components/DynamicTable/helpers/configureColumns';
import { formatDbRecordListData } from '../../../records/components/DynamicTable/helpers/configureRows';
import { initializeRecordForm } from '../../../records/components/Forms/store/actions';
import {
  addRecordToShortList,
  getRecordByIdRequest,
  IAddRecordToShortList,
  IGetRecordById,
  updateRecordByIdRequest,
} from '../../../records/store/actions';
import { IRecordReducer } from '../../../records/store/reducer';
import {
  getSchemaByIdRequest,
  getSchemaByModuleAndEntityRequest,
  ISchemaById,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociationWithNestedEntities,
} from '../../store/actions';
import { IRecordAssociationsReducer } from '../../store/reducer';
import ListActionMenu from '../ListActions/ListActionMenu';
import CoreForm from '../../../records/components/Forms/CoreForm';
import './styles.scss';
import { IOpenRecordDrawer, openRecordDrawer } from '../../../userInterface/store/actions';
import { getAllSchemaAssociationsMatchingEntityName } from './helpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';

interface Props {
  userReducer: any;
  title?: string;
  expandable?: any;
  moduleName: string;
  entityName: string;
  nestedEntityName?: string;
  ignoreRelatedAssociationIdForNested?: boolean;
  record: DbRecordEntityTransform;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => {};
  getSchemaById: (params: ISchemaById, cb: any) => {};
  updateRecord: any;
  getAssociationWithNestedEntities: (
    params: IGetRecordAssociationWithNestedEntities,
    cb?: any,
  ) => {};
  shortListRecord: (params: IAddRecordToShortList) => {};
  getRecordById: (payload: IGetRecordById, cb: any) => {};
  hidden?: string[];
  filters?: string[];
  isCreateHidden?: boolean;
  customActionOverride?: boolean;
  disableRelatedProductEdit?: boolean;
  thumbnailSize?: number; // 8, 12, 24
  hideViewOptions?: boolean;
  relationType?: RelationTypeEnum;
  sortRecords?: (records: DbRecordEntityTransform[]) => DbRecordEntityTransform[];
  isEditDisabled?: boolean;
  location?: 'sidebar' | 'association';
  disableUpload?: boolean;
  displayType?: AssociatedFilesDisplayType;
  editableFileFields?: Array<string>;
  showFileCategoryForType?: string | undefined;
  initializeForm: Function;
  collapsedByDefault?: boolean;
  hideIcon?: boolean;
  showAllRecords?: boolean;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

interface State {
  compactTable: boolean;
  activeCollapse: boolean;
  data: DbRecordEntityTransform[] | undefined;
  uuid: string | undefined;
}

class AssociationDataTable extends React.Component<Props, State> {
  timer: NodeJS.Timeout | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      compactTable: true,
      activeCollapse: false,
      data: undefined,
      uuid: uuidv4(),
    };
  }

  componentDidMount() {
    const { moduleName, entityName, getSchema, nestedEntityName, schemaReducer } = this.props;

    // Fetch schemas for association
    const shortListSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (shortListSchema) {
      this.getRecordAssociations();
    } else {
      getSchema({ moduleName, entityName }, () => {
        this.getRecordAssociations();
      });
    }

    // Fetch schemas for nested association
    if (nestedEntityName) {
      const nestedShortListSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        nestedEntityName,
      );

      if (nestedShortListSchema) {
        this.getRecordAssociations();
      } else {
        getSchema({ moduleName, entityName: nestedEntityName }, (res: SchemaEntity) => {
          this.getRecordAssociations();
        });
      }
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      prevProps.record?.id !== this.props.record?.id &&
      !this.props.recordAssociationReducer.isRequesting
    ) {
      this.getRecordAssociations();
    }

    // When Collapse is expanded, start polling
    if (!prevState.activeCollapse && this.state.activeCollapse) {
      this.startTimer();
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      if (!document.hidden) {
        this.getRecordAssociations();
      }
    }, 5000);
  }

  clearTimer() {
    clearInterval(this.timer);
    this.timer = undefined;
  }

  // We either initialize a record update form or a record association update form
  initializeUpdateForm = (editedRecord: any, hasColumnMappings?: any) => {
    const { initializeForm, getSchemaById, record } = this.props;
    const parentRecordEntityName = record.entity?.split(':')[1];

    getSchemaById({ schemaId: editedRecord?.schemaId }, (resultSchema: SchemaEntity) => {
      const schemaHasTypes = resultSchema?.types?.length > 0;

      initializeForm({
        formUUID: this.state.uuid,
        title: hasColumnMappings
          ? `Update Linked Record (${parentRecordEntityName} → ${resultSchema.entityName})`
          : `Update ${resultSchema.entityName}`,
        showFormModal: true,
        isUpdateReq: true,
        schema: resultSchema,
        hideRecordTypeField: !schemaHasTypes || !editedRecord.type,
        recordType: editedRecord?.type ? editedRecord?.type : '',
        selected: editedRecord,
        hasColumnMappings: hasColumnMappings,
        sections: [{ name: resultSchema.name, schema: resultSchema }],
        relatedEntityName: hasColumnMappings ? parentRecordEntityName : undefined,
      });
    });
  };

  parseDataSource(
    records: DbRecordEntityTransform[],
    relation: DbRecordAssociationRecordsTransform,
  ) {
    const { schemaReducer, entityName, relationType, sortRecords, record } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, relation?.schemaId);

    const hasPipelines = [
      'WorkOrder',
      'Order',
      'ReturnOrder',
      'Lead',
      'Account',
      'Program',
      'Project',
      'Task',
      'Job',
    ].includes(entityName);

    let data = records;
    // sort data on Position column
    if (['Job'].includes(entityName)) {
      data = data.sort(
        (recA, recB) => Number(getProperty(recA, 'Order')) - Number(getProperty(recB, 'Order')),
      );
    }

    // ODN-1899 sort records
    if (sortRecords) {
      data = sortRecords(data);
    }

    if (relationType) {
      data = data.filter((elem) => elem.relationType === relationType);
    }

    const { tableRows } = formatDbRecordListData(schema, data, hasPipelines);
    return tableRows;
  }

  parseColumns(
    records: DbRecordEntityTransform[],
    relation: DbRecordAssociationRecordsTransform,
    schemaTypeId?: string,
  ) {
    const {
      record,
      schemaReducer,
      entityName,
      shortListRecord,
      getRecordById,
      hidden,
      disableRelatedProductEdit,
      userReducer,
      isEditDisabled,
      openDrawer,
    } = this.props;

    // use schema of the required relation (primary relation or nested)
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, relation?.schema?.id);
    const parentSchema: SchemaEntity | undefined = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record?.schemaId,
    );

    if (schema && schema.columns) {
      const hasPipelines = [
        'WorkOrder',
        'Order',
        'ReturnOrder',
        'Lead',
        'Account',
        'Program',
        'Project',
        'Task',
        'Job',
      ].includes(entityName);

      let disableDataTableEdit = isEditDisabled ? isEditDisabled : disableRelatedProductEdit;
      if (
        relation.schemaAssociation.label === 'PriceBook__Product' &&
        !isSystemAdmin(userReducer)
      ) {
        disableDataTableEdit = true;
      }

      const filteredColumns = schema.columns.filter(
        (elem) => elem.schemaTypeId === schemaTypeId || !elem.schemaTypeId,
      );

      const schemaFiltered = Object.assign({}, schema, {
        columns: filteredColumns,
      });

      const hasLabel = () => {
        if (
          parentSchema &&
          getAllSchemaAssociationsMatchingEntityName(parentSchema?.associations, entityName)
            .length > 1
        ) {
          return true;
        } else {
          false;
        }
      };

      const defaultColumns = getElasticSearchKeysFromSchemaColumn(schema, schemaTypeId);

      let columns = formatDbRecordListColumns(
        schemaFiltered,
        defaultColumns,
        records,
        hasPipelines,
        shortListRecord,
        openDrawer,
        getRecordById,
        {
          record,
          relation,
          hidden,
        },
        disableDataTableEdit,
        canUserGetRecord(userReducer, relation?.schema),
        userReducer,
        this.initializeUpdateForm,
        hasLabel(), // <- has label
      );

      return columns;
    }
  }

  hasData() {
    const { record, entityName, recordAssociationReducer } = this.props;
    const associationKey = `${record?.id}_${entityName}`;
    const associationObj: any = recordAssociationReducer.shortList[associationKey];
    return !!(associationObj && associationObj[entityName] && associationObj[entityName].dbRecords);
  }

  // When a panel is closed, kill the polling timeout.
  onCollapsePanelChange = (panel: string[]) => {
    // All entities apart from File
    if (this.props.entityName !== 'File') {
      this.setState({ activeCollapse: !this.state.activeCollapse });

      // Remove interval timer when association Collapse is closed
      if (panel.length === 0) {
        this.clearTimer();
      }
    }
    // File entities
    else {
      if (panel.length > 0) {
        this.startTimer();
      } else {
        this.clearTimer();
      }
    }
  };

  associationDataTableInCard(
    title: string,
    relation: DbRecordAssociationRecordsTransform,
    data: any[],
    schemaType?: SchemaTypeEntity,
  ) {
    const { expandable, record, hidden, isCreateHidden, customActionOverride, isEditDisabled } =
      this.props;

    let filteredData: DbRecordEntityTransform[] = data!;
    if (data!.length > 4 && this.state.compactTable && !this.props.showAllRecords) {
      filteredData = data.slice(0, 4);
    }

    // Render AssociationDataTable raw by default, but control collapse mode with a prop
    return (
      <Collapse
        size="small"
        key={title}
        bordered={true}
        onChange={(e: any) => this.onCollapsePanelChange(e)}
        style={{ marginBottom: 15 }}
        activeKey={this.state.activeCollapse || this.props.collapsedByDefault ? title : undefined}
      >
        <Collapse.Panel
          key={title}
          className="associationTableCollapsePanel"
          showArrow={false}
          header={
            <>
              <span>{title}</span>
              <Badge
                count={data?.length}
                showZero
                style={{
                  backgroundColor: '#f0f0f0',
                  color: '#262626',
                  marginTop: 1,
                  border: 'none',
                  marginLeft: 8,
                  marginBottom: 2,
                }}
              />
            </>
          }
          extra={
            <div onClick={(e: any) => e.stopPropagation()}>
              <ListActionMenu
                compact
                record={record}
                relation={relation}
                hidden={hidden}
                isCreateHidden={isCreateHidden}
                schemaType={schemaType}
                customActionOverride={customActionOverride}
                isEditDisabled={isEditDisabled}
              />
            </div>
          }
        >
          <Row key={'row-' + schemaType?.id}>
            <Col span={24} key={'col-' + schemaType?.id}>
              <CoreForm
                type="MODAL"
                formUUID={this.state.uuid!}
                onSubmitEvent={(params: any) => false}
              />
              <Table
                style={{ width: '100%' }}
                showSorterTooltip={false}
                size="small"
                tableLayout="auto"
                scroll={{ x: 1300 }}
                expandable={expandable}
                pagination={
                  filteredData.length > 4 && !this.props.showAllRecords
                    ? { position: ['bottomRight'] }
                    : false
                }
                dataSource={filteredData ? this.parseDataSource(filteredData, relation) : []}
                //@ts-ignore
                columns={
                  filteredData ? this.parseColumns(filteredData, relation, schemaType?.id) : []
                }
              />
            </Col>
            {/* Show more... */}
            <Col
              className="collapseShowMore"
              onClick={() => this.setState({ compactTable: !this.state.compactTable })}
              span={24}
              style={{
                textAlign: 'center',
                display: data?.length <= 4 || this.props.showAllRecords ? 'none' : 'block',
                backgroundColor: '#fafafa',
              }}
            >
              <Button size="small" style={{ margin: 5 }}>
                {this.state.compactTable ? 'Show More' : 'Show Less'}
              </Button>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    );
  }

  filteredRecordsByType = (records: Array<DbRecordEntityTransform>, schemaType: string) => {
    if (records?.length > 0 || schemaType) {
      return records.filter((record: DbRecordEntityTransform) => schemaType === record.type);
    } else {
      return [];
    }
  };

  render() {
    const {
      location,
      title,
      record,
      entityName,
      filters,
      schemaReducer,
      recordAssociationReducer,
      nestedEntityName,
      ignoreRelatedAssociationIdForNested,
    } = this.props;

    const associationKey = filters
      ? `${record?.id}_${entityName}_${filters}`
      : `${record?.id}_${entityName}`;
    const associationObj: any = recordAssociationReducer.shortList[associationKey];

    // get primary relation data by entityName
    const primaryRelation = associationObj && associationObj[entityName];
    const primaryRecords = primaryRelation?.dbRecords;

    // check if nested relation by nestedEntityName was loaded
    // and get nested relation data
    const primaryRecordWithNestedRelation =
      primaryRecords && primaryRecords.find((elem: any) => elem[`${nestedEntityName}`]);
    const nestedRelation =
      primaryRecordWithNestedRelation && primaryRecordWithNestedRelation[`${nestedEntityName}`];

    let nestedRecords;
    if (nestedRelation && !ignoreRelatedAssociationIdForNested) {
      // if primary relation has relatedAssociationId, by default we filter nested relations by it
      // filter primary records that haven't relatedAssociationId specified
      const filtered =
        primaryRecords &&
        nestedRelation &&
        primaryRecords.filter((elem: any) => elem.dbRecordAssociation?.relatedAssociationId);
      // then filter nested relations by relatedAssociationId
      nestedRecords = filtered.flatMap((elem: any) => {
        return elem[`${nestedEntityName}`]?.dbRecords?.filter((nelem: any) => {
          return (
            nelem.dbRecordAssociation?.id === elem.dbRecordAssociation?.relatedAssociationId ||
            nelem.dbRecordAssociation?.relatedAssociationId ===
              elem.dbRecordAssociation?.relatedAssociationId
          );
        });
      });
    } else {
      nestedRecords =
        primaryRecords &&
        nestedRelation &&
        primaryRecords.flatMap((elem: any) => elem[`${nestedEntityName}`].dbRecords);
    }

    // choose what relations data to use for the table: primary or nested
    const tableEntityName = nestedRelation && nestedEntityName ? nestedEntityName : entityName;
    const relation = nestedRelation ?? primaryRelation;
    let data =
      nestedRelation && nestedRecords
        ? nestedRecords.length > 0
          ? nestedRecords
          : undefined
        : primaryRecords;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, relation?.schema?.id);

    // Get associated schemas by entity, get types and constraints
    let schemaTypesToRender: Array<any> = [];
    const recordSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList!,
      record?.schemaId,
    );
    const schemaAssociations = getSchemaAssociationByEntity(recordSchema!, schema?.entityName!);
    if (schemaAssociations) {
      schemaTypesToRender = getChildSchemaTypes(schemaAssociations, record.type!);
    }

    const fileCountByType = (schemaType: string) => {
      const files = data?.filter((f: any) => f.type === schemaType);
      return files?.length || 0;
    };

    // If entity has multiple schema associations that only differ by label,
    // pass the label inside record. This is used to display the label in the table.
    const hasLabel = () => {
      if (
        recordSchema &&
        getAllSchemaAssociationsMatchingEntityName(recordSchema?.associations, entityName).length >
          1
      ) {
        return true;
      } else {
        false;
      }
    };
    if (hasLabel() && recordSchema) {
      data = data?.map((record: DbRecordEntityTransform) => {
        const association = getAllSchemaAssociationsMatchingEntityName(
          recordSchema?.associations,
          entityName,
        ).find((a: SchemaAssociationEntity) => a.id === record.schemaAssociationId);

        if (association) {
          const label = association.label;
          return {
            ...record,
            label: label,
          };
        } else {
          return record;
        }
      });
    }

    if (tableEntityName === 'File') {
      if (schemaTypesToRender.length > 0) {
        // If there are more than one file schema types, and one of them is DEFAULT
        // move DEFAULT to the beginning of the array. This means that DEFAULT file types
        // association files get to be on the top, easily accessible.
        if (schemaTypesToRender?.includes('DEFAULT') && schemaTypesToRender?.length > 1) {
          schemaTypesToRender = schemaTypesToRender.filter((item) => item !== 'DEFAULT');
          schemaTypesToRender.unshift('DEFAULT');
        }

        // Check if type has data, we use this to collapse the image panel if there's more than one record
        const typeHasData = (schemaType: string) => {
          return data?.filter((f: any) => f.type === schemaType).length > 0;
        };

        return schemaTypesToRender.map((schemaType: string) => (
          <Collapse
            key={schemaType}
            bordered={true}
            onChange={(e: any) => this.onCollapsePanelChange(e)}
            defaultActiveKey={
              this.props.collapsedByDefault || typeHasData(schemaType) ? 'Files' : undefined
            }
            style={{ marginBottom: 18 }}
          >
            <Collapse.Panel
              key={'Files'}
              className={`associationTableCollapsePanel ${location === 'sidebar' ? 'sidebar' : ''}`}
              showArrow={false}
              header={
                <>
                  <span>File ({schemaType})</span>
                  <Badge
                    count={fileCountByType(schemaType)}
                    showZero
                    style={{
                      backgroundColor: '#f0f0f0',
                      color: '#262626',
                      marginTop: 1,
                      border: 'none',
                      marginLeft: 8,
                      marginBottom: 2,
                    }}
                  />
                </>
              }
            >
              <FileByTypeAssociation
                files={data}
                schemaType={schemaType}
                record={record!} // Parent record
                dataSource={this.parseDataSource(data, relation)}
                columns={this.parseColumns(data, relation)}
                disableUpload={this.props.disableUpload}
                displayType={
                  this.props.location === 'association' && this.props.displayType !== 'thumbnails'
                    ? 'list'
                    : 'thumbnails'
                }
                editableFileFields={this.props.editableFileFields}
                thumbnailSize={this.props.thumbnailSize}
                showFileCategoryForType={this.props.showFileCategoryForType}
              />
            </Collapse.Panel>
          </Collapse>
        ));
      } else {
        return (
          <Collapse
            key={'Files'}
            bordered={true}
            defaultActiveKey={'Files'}
            style={{ marginBottom: 10 }}
          >
            <Collapse.Panel
              key={'Files'}
              className={`associationTableCollapsePanel ${location === 'sidebar' ? 'sidebar' : ''}`}
              showArrow={false}
              header={<span>File</span>}
            >
              <div style={{ padding: 80 }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Files to show</span>}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
        );
      }
    } else {
      // For schema types we want to render the lists grouped by type. However, skip this if schema
      // has types but every record has no type. There is a fallthrough case that happens often and
      // we still want users to see the table in these scenarios.
      if (
        schema &&
        schema.types &&
        schema.types.length > 0 &&
        !data?.every((item: DbRecordEntityTransform) => !item.type)
      ) {
        const recordTypes = data ? data.map((elem: DbRecordEntityTransform) => elem.type) : [];
        const filtered = schema.types.filter((elem: any) => recordTypes.includes(elem.name));

        if (relation && !data) {
          return this.associationDataTableInCard(`${tableEntityName}`, relation, [], undefined);
        }

        return filtered.map((elem: SchemaTypeEntity) =>
          this.associationDataTableInCard(
            `${tableEntityName} (${elem.name})`,
            relation,
            data.filter((record: DbRecordEntityTransform) => record.type === elem.name),
            elem,
          ),
        );
      } else {
        return this.associationDataTableInCard(title || tableEntityName, relation, data || []);
      }
    }
  }

  private getRecordAssociations() {
    const {
      getAssociationWithNestedEntities,
      schemaReducer,
      getSchemaById,
      entityName,
      nestedEntityName,
      record,
      filters,
    } = this.props;

    // request relations from module that hosts record entity
    if (record) {
      const shortlistParentRecordSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );

      if (shortlistParentRecordSchema && shortlistParentRecordSchema.associations) {
        getAssociationWithNestedEntities(
          {
            recordId: record.id,
            key: filters ? `${entityName}_${filters}` : entityName,
            schema: shortlistParentRecordSchema,
            entity: entityName,
            nestedEntities: nestedEntityName ? [nestedEntityName] : [],
            filters,
            // ODN-2224 load links if there are lookup fields
            withLinks: this.checkIfNeedsWithLinks(shortlistParentRecordSchema, entityName),
          },
          (results: any) => {
            let returnedData: DbRecordEntityTransform[] =
              results.results[entityName]?.dbRecords || [];

            if (returnedData.length > 0) {
              if (this.state.data === undefined) {
                this.setState({
                  activeCollapse: true,
                  data: returnedData,
                });
              } else {
                this.setState({
                  data: returnedData,
                });
              }
            } else {
              this.setState({ data: [] });
            }
          },
        );
      } else {
        getSchemaById({ schemaId: record.schemaId as string }, (result: SchemaEntity) => {
          // request to /one-relation to load nested entity
          getAssociationWithNestedEntities({
            recordId: record.id,
            key: filters ? `${entityName}_${filters}` : entityName,
            schema: result,
            entity: entityName,
            nestedEntities: nestedEntityName ? [nestedEntityName] : [],
            filters,
            // ODN-2224 load links if there are lookup fields
            withLinks: this.checkIfNeedsWithLinks(result, entityName),
          });
        });
      }
    }
  }

  /**
   * ODN-2224 Checks if related entity schema contains LOOKUP fields
   * @param schema
   * @param entityName
   * @returns
   */
  private checkIfNeedsWithLinks(schema: SchemaEntity, entityName: string): boolean | undefined {
    if (!schema || !entityName) {
      return;
    }

    const relatedSchema = schema.associations
      ?.map((a) => (a.parentSchemaId === schema.id ? a.childSchema : a.parentSchema))
      ?.find((s) => s?.entityName === entityName);

    return relatedSchema?.columns?.some((c) => c.type === SchemaColumnTypes.LOOKUP);
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getSchemaById: (payload: ISchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  updateRecord: (params: any, cb: any) => dispatch(updateRecordByIdRequest(params, cb)),
  getAssociationWithNestedEntities: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(AssociationDataTable);
