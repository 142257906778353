import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import ActionBuilder from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionBuilder/ActionBuilder';
import FlowBuilder from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration/SchemaActionBuilder/FlowBuilder';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
interface Props {}

const SchemaActionBuilder: React.FC<Props> = (props: Props) => {
  const { schemaAction, definition, builderContainerRef } = useContext(
    SchemaActionDefinitionContext,
  );

  return (
    <Row
      ref={builderContainerRef}
      justify="center"
      style={{
        overflowY: 'auto',
        background: '#F6F7F9',
        height: 'calc(100vh - 90px)',
      }}
    >
      <Col
        span={13}
        style={{
          padding: '15px 30px',
        }}
      >
        {schemaAction?.isStepFlow ? <FlowBuilder /> : <ActionBuilder />}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SchemaActionBuilder);
