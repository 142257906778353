import {
  Alert,
  Button,
  Icon,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from '@blueprintjs/core';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { splitEntityToModuleAndEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { initailizeCancelAppointmentModal } from '@legacy/core/appointments/store/actions';
import { listUsers } from '@legacy/core/identity/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getPipelinesByModuleAndEntity } from '@legacy/core/pipelines/store/actions';
import CoreForm, {
  Props as OdinFormModalProps,
} from '@legacy/core/records/components/Forms/CoreForm';
import { InputChangeParams } from '@legacy/core/records/components/Forms/FormFields';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import AssignGroupsToRecords from '@legacy/core/records/components/Groups/AssignGroupsToRecords';
import {
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IDeleteRecordById,
  IGetRecordById,
  IRestoreRecordById,
  restoreRecordByIdRequest,
} from '@legacy/core/records/store/actions';
import {
  CREATE_DB_RECORD_REQUEST,
  UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '@legacy/core/records/store/constants';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import {
  deleteRecordAssociationById,
  getRecordAssociationByIdRequest,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociationById,
  IGetRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import { DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST } from '@legacy/core/recordsAssociations/store/constants';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { toggleAssignRecordToGroupModal } from '@legacy/core/userInterface/store/actions';
import { IInitializeSwapAddress, initializeSwapAddress } from '@legacy/core/workflow/store/actions';
import { initProcessWorkflowForm } from '@legacy/core/workflowEngine/components/ProcessWorkflow/store/actions';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import history from '../../helpers/browserHistory';
import {
  canUserCloneRecord,
  canUserDeleteRecord,
  canUserUpdateRecord,
  hasAnyRoles,
  hasPermissions,
  isSystemAdmin,
} from '../../helpers/rbacRules';
import {
  checkRecordIsLocked,
  getAllSchemaAssociationEntities,
  getBrowserPath,
} from '../../helpers/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../helpers/schemaHelpers';

const { PRODUCT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { NOTE, PRODUCT } = SchemaModuleEntityTypeEnums;

const excludeFromClone = {
  Offer: ['OrderModule:OrderItem', 'CrmModule:Lead'],
  PriceBook: ['OrderModule:OrderItem', 'ProductModule:Vendor'],
  Contact: [
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Order: [
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Product: [
    'OrderModule:OrderItem',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Discount: [
    'OrderModule:OrderItem',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Account: [
    'OrderModule:Order',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'CrmModule:Lead',
  ],
  Address: [
    'OrderModule:Order',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'CrmModule:Lead',
    'CrmModule:Contact',
    'CrmModule:Account',
    'CrmModule:Visit',
    'ServiceModule:CustomerDeviceOnt',
  ],
};

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  record: DbRecordEntityTransform;
  match: any;
  userReducer: any;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  initializeForm: any;
  deleteRecord: (param: IDeleteRecordById, cb: (res: any) => {}) => {};
  restoreRecord: (param: IRestoreRecordById, cb: (res: any) => {}) => {};
  getRecord: any;
  getRelatedRecordById: any;
  getUsers: any;
  getPipelines: any;
  getAssociations: any;
  hasColumnMappings?: boolean;
  visibleProperties?: string[];
  children?: any;
  style?: any;
  onDelete?: any;
  initializeCancelAppointment: any;
  updateFormDisabledFields?: string[];
  updateFromCustomValidations?: { [key: string]: any };
  updateFormAdditionalInputChangeHandler?: (
    updateFormProps: OdinFormModalProps,
    params: InputChangeParams,
  ) => void;
  initializeSwapAddress: (params: IInitializeSwapAddress) => void;
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) => void;
  alertMessage: any;
  initProcessWorkflowForm: (params: any) => void;
  entityName?: string;
  onRecalculate?: (record: DbRecordEntityTransform) => void;
  toggleAssignGroupModal: () => void;
  showViewConfiguration?: boolean;
  isQuickView?: boolean;
};

interface State {
  deleteFileModalVisible: boolean;
  restoreFileModalVisible: boolean;
  isDeletingFile: boolean;
  isRestoringFile: boolean;
}

class ActionMenuDetailView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      deleteFileModalVisible: false,
      restoreFileModalVisible: false,
      isDeletingFile: false,
      isRestoringFile: false,
    };
  }

  async initializeCloneForm() {
    const {
      record,
      schemaReducer,
      initializeForm,
      getUsers,
      getPipelines,
      getAssociations,
      visibleProperties,
      isQuickView,
    } = this.props;

    getUsers();

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (schema) {
      getPipelines({ schema: schema });

      initializeForm({
        formUUID: `${record.id}${isQuickView ? '-quickView' : ''}`,
        title: 'Initializing',
        showInitializing: true,
      });

      getAssociations(
        {
          recordId: record.id,
          schema,
          entities: getAllSchemaAssociationEntities(schema.associations, [NOTE]),
        },
        (params: { results: DbRecordAssociationRecordsTransform[] }) => {
          // parse associations into related records

          const sectionAssociations: any[] = [];
          const modifiedAssociations: any[] = [];

          for (const key of Object.keys(params.results)) {
            // @ts-ignore
            if (params.results[key].dbRecords) {
              // @ts-ignore
              params.results[key].dbRecords
                .filter((elem: { entity: string }) =>
                  // @ts-ignore
                  excludeFromClone[schema.entityName]
                    ? // @ts-ignore
                      !excludeFromClone[schema.entityName].includes(elem.entity)
                    : true,
                )
                .map((elem: DbRecordEntityTransform) => {
                  sectionAssociations.push({
                    entity: elem.entity,
                    recordId: elem.id,
                    title: `${elem.entity}: ${elem.title}`,
                  });

                  modifiedAssociations.push({
                    entity: elem.entity,
                    recordId: elem.id,
                    relatedAssociationId: elem.dbRecordAssociation?.relatedAssociationId,
                  });
                });
            }
          }

          let newRecord = JSON.parse(JSON.stringify(record));
          let tempArr: any[] = [];

          schema.columns.forEach((element) => {
            const uniques = element.validators.find((item) => item.type == 'UNIQUE');
            if (uniques) {
              tempArr.push(element.name);
            }
          });

          if (tempArr.length) {
            tempArr.forEach((element) => {
              newRecord.properties[element] = null;
            });
          }

          initializeForm({
            formUUID: `${record.id}${isQuickView ? '-quickView' : ''}`,
            title: `Clone ${schema.entityName}`,
            showFormModal: true,
            showInitializing: false,
            isCreateReq: true,
            isCloning: true,
            schema: schema,
            selected: newRecord,
            recordType: record.type,
            visibleFieldOverride: visibleProperties,
            sections: [
              {
                name: schema.name,
                schema: schema,
                associations: sectionAssociations,
              },
            ],
            modified: [
              {
                schemaId: schema.id,
                type: record.type,
                title: record.title,
                ownerId: record.ownedBy?.id,
                properties: record.properties,
                associations: modifiedAssociations,
              },
            ],
          });
        },
      );
    }
  }

  async initializeUpdateForm() {
    const {
      record,
      schemaReducer,
      initializeForm,
      getUsers,
      hasColumnMappings,
      getPipelines,
      visibleProperties,
      updateFormDisabledFields,
      updateFromCustomValidations,
      updateFormAdditionalInputChangeHandler,
      isQuickView,
    } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (schema) {
      getUsers();
      getPipelines({ schema: schema });

      const relatedEntityName = this.props?.match?.params?.relatedRecordEntityName;

      initializeForm({
        formUUID: `${record.id}${isQuickView ? '-quickView' : ''}`,
        title: hasColumnMappings
          ? `Update Linked Record (${relatedEntityName} → ${schema.entityName})`
          : `Update ${schema.entityName}`,
        hasColumnMappings,
        visibleFieldOverride: visibleProperties,
        disabledFields: updateFormDisabledFields,
        customValidations: updateFromCustomValidations,
        additionalInputChangeHandler: updateFormAdditionalInputChangeHandler,
        showFormModal: true,
        isUpdateReq: true,
        schema: schema,
        selected: record,
        recordType: record.type,
        sections: [{ name: schema.name, schema: schema }],
        relatedEntityName,
      });
    }
  }

  showSwapAddressModal() {
    const { initializeSwapAddress, record } = this.props;
    initializeSwapAddress({
      isSwapAddressVisible: true,
      addressRecord: record,
    });
  }

  menuItems = () => {
    const {
      userReducer,
      record,
      schemaReducer,
      hasColumnMappings,
      toggleAssignGroupModal,
      showViewConfiguration,
    } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <Menu>
        <MenuItem
          icon={<Icon icon="edit" />}
          text="Edit"
          key="menuItemEdit"
          disabled={
            !!record?.dbRecordAssociation?.relatedAssociationId ||
            !canUserUpdateRecord(userReducer, schema, record) ||
            checkRecordIsLocked(record)
          }
          onClick={() => this.initializeUpdateForm()}
        />

        <MenuItem
          icon={<Icon icon="duplicate" />}
          key="menuItemClone"
          text="Clone"
          disabled={!canUserCloneRecord(userReducer, schema, record)}
          onClick={() => this.initializeCloneForm()}
        />

        {/* Restore */}
        {isSystemAdmin(userReducer) && (
          <MenuItem
            text="Restore"
            icon={<Icon icon="redo" />}
            key="menuItemRestore"
            disabled={!isSystemAdmin(userReducer)}
            onClick={() => this.setState({ restoreFileModalVisible: true })}
          />
        )}

        {/* Full View */}
        {hasColumnMappings && (
          <MenuItem
            key="menuItemFullView"
            text={
              <Link target="_blank" to={getBrowserPath(record)}>
                Full View
              </Link>
            }
          />
        )}

        {/* Process Workflow */}
        <MenuItem
          icon={<Icon icon="flows" />}
          text="Process Workflow"
          key="menuItemProcessWorkflow"
          disabled={!canUserUpdateRecord(userReducer, schema, record)}
          onClick={() => this.initializeProcessWorkflowForm(record)}
        />
        {
          // render the Re-calculate action
          ['OrderModule:Order', 'BillingModule:Invoice'].includes(String(record?.entity)) &&
          this.props.onRecalculate ? (
            <MenuItem
              text="Recalculate"
              key="menuItemReCalculate"
              disabled={
                !(
                  (record.entity === 'OrderModule:Order' &&
                    hasPermissions(userReducer, ['ordermodule.order.recalculate'])) ||
                  (record.entity === 'BillingModule:Invoice' &&
                    hasPermissions(userReducer, ['billingmodule.invoice.recalculate']))
                ) || !isSystemAdmin(userReducer)
              }
              onClick={() => {
                if (this.props.onRecalculate) {
                  this.props.onRecalculate(record);
                }
              }}
            >
              Recalculate
            </MenuItem>
          ) : (
            <></>
          )
        }

        {/* Manage Groups on Record */}
        <MenuItem
          icon={<Icon icon="people" />}
          key="menuItemViewAssignGroups"
          disabled={!hasPermissions(userReducer, ['groups.assign'])}
          onClick={toggleAssignGroupModal}
          text="Manage Groups"
        />

        {/* Show View Config */}
        {isSystemAdmin(userReducer) && schema && showViewConfiguration && (
          <Link
            to={`/${SCHEMA_MODULE}/Schema/${schema?.id}/#ViewConfigs`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemShowViewConfigs" text="Show View Configurations" />
          </Link>
        )}

        {/* View Schema */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/ControlPanelModule/SchemaManager/${schema?.id}`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemShowSchema" text="View Schema" />
          </Link>
        )}

        {/* View Actions */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/ControlPanelModule/Forms/ListView#entityName=${schema?.entityName}`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemViewActions" text="View Actions" />
          </Link>
        )}

        {/* View Actions */}
        {isSystemAdmin(userReducer) && schema && (
          <Link
            to={`/${SCHEMA_MODULE}/Schema/${schema?.id}#Pipelines`}
            target="_blank"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <MenuItem key="menuItemViewPipelines" text="View Pipelines" />
          </Link>
        )}

        <MenuDivider />

        {/* Delete */}
        {record?.entity === 'FieldServiceModule:ServiceAppointment' ? (
          <MenuItem
            text="Delete"
            intent="danger"
            icon={<Icon icon="trash" />}
            key="menuItemDelete1"
            disabled={
              !schema ||
              !canUserDeleteRecord(userReducer, schema, record) ||
              // ODN-1640 prevent deleting if record is locked
              (checkRecordIsLocked(record) &&
                !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin'))
            }
            onClick={() => this.initializeDeleteAppointmentForm()}
          />
        ) : (
          <MenuItem
            icon={<Icon icon="trash" />}
            intent="danger"
            text="Delete"
            onClick={() => this.setState({ deleteFileModalVisible: true })}
            key="menuItemDelete2"
            disabled={
              !schema ||
              !canUserDeleteRecord(userReducer, schema, record) ||
              checkRecordIsLocked(record)
            }
          />
        )}
      </Menu>
    );
  };

  render() {
    const { record, schemaReducer, hasColumnMappings, style, isQuickView } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <div style={style}>
        <AssignGroupsToRecords schema={schema} singleRecord={record} />

        {/* Delete File Modal */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          loading={this.state.isDeletingFile}
          intent={Intent.DANGER}
          isOpen={this.state.deleteFileModalVisible}
          onCancel={() => this.setState({ deleteFileModalVisible: false })}
          onConfirm={() => this.deleteRecord()}
        >
          <p>Are you sure you want to delete this record? You will be able to restore it later.</p>
        </Alert>

        {/* Restore File Modal */}
        <Alert
          cancelButtonText="Cancel"
          confirmButtonText="Restore"
          loading={this.state.isRestoringFile}
          intent={Intent.PRIMARY}
          isOpen={this.state.restoreFileModalVisible}
          onCancel={() => this.setState({ restoreFileModalVisible: false })}
          onConfirm={() => this.restoreRecord()}
        >
          <p>Are you sure you want to restore this record?</p>
        </Alert>

        <CoreForm
          type="MODAL"
          formUUID={`${record.id}${isQuickView ? '-quickView' : ''}`}
          hasColumnMappings={hasColumnMappings}
          onSubmitEvent={(params: { event: string; results: any }) => this.handleFormSubmit(params)}
        />
        <Popover content={<Menu>{this.menuItems()}</Menu>} placement="bottom">
          <Button alignText="left" rightIcon="caret-down" />
        </Popover>
      </div>
    );
  }

  private deleteRecord() {
    const { record, schemaReducer, deleteRecord, onDelete, deleteRecordAssociation } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    const dbRecordAssociationId = this.props.match.params.dbRecordAssociationId;

    if (schema) {
      this.setState({ isDeletingFile: true });

      // check if the path matches related product
      // if so, don't delete the product but association with related record
      // the record association id is extracted from the URL
      if (
        window.location.pathname.includes(`/${PRODUCT_MODULE}/${PRODUCT}/`) &&
        window.location.pathname.includes(`/related/`) &&
        dbRecordAssociationId
      ) {
        const schemaAssociation = schema?.associations.find(
          (elem) => elem.label === 'PriceBook__Product',
        );
        deleteRecordAssociation(
          {
            schema: schema as SchemaEntity,
            schemaAssociation: schemaAssociation as SchemaAssociationEntity,
            dbRecordAssociationId: dbRecordAssociationId,
          },
          () => {
            this.setState({ deleteFileModalVisible: false, isDeletingFile: false });
            window.history.back();
          },
        );
      } else {
        if (schema && record) {
          deleteRecord(
            {
              schema: schema,
              recordId: record.id,
            },
            () => {
              this.setState({ deleteFileModalVisible: false, isDeletingFile: false });
              if (onDelete) {
                return onDelete();
              } else {
                history.push(`/${schema.moduleName}/${schema.entityName}`);
              }
            },
          );
        }
      }
    }
  }

  private restoreRecord() {
    const { record, schemaReducer, restoreRecord } = this.props;

    if (record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

      if (schema) {
        this.setState({ isRestoringFile: true });
        restoreRecord(
          {
            schema,
            recordId: record.id,
          },
          (res) => {
            this.setState({
              restoreFileModalVisible: false,
              isRestoringFile: false,
            });
            return res;
          },
        );
      }
    }
  }

  private handleFormSubmit(params: { event: string; results: any }) {
    const { getRecord, getRelatedRecordById, record, schemaReducer } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    switch (params.event) {
      case DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST:
        getRelatedRecordById({
          parentSchema: schema,
          recordId: record.id,
          dbRecordAssociationId: record?.dbRecordAssociation?.id,
        });
        break;
      case UPDATE_DB_RECORD_BY_ID_REQUEST:
        getRecord({ schema, recordId: params.results.id });
        break;
      case CREATE_DB_RECORD_REQUEST:
        history.push(`${getBrowserPath(params.results)}`);
        break;
    }
  }

  private initializeDeleteAppointmentForm() {
    const { record, initializeCancelAppointment } = this.props;
    console.log('debug: record', record);
    initializeCancelAppointment({
      cancelModalVisible: true,
      cancelRelatedRecord: record,
      deleteFromDetail: true,
      schemaType: 'SA_CANCEL',
    });
  }

  private initializeProcessWorkflowForm(record: DbRecordEntityTransform) {
    const { initProcessWorkflowForm } = this.props;

    if (record?.entity) {
      const { entityName } = splitEntityToModuleAndEntity(record.entity);
      if (entityName === SchemaModuleEntityTypeEnums.WORKFLOW) {
        initProcessWorkflowForm({
          workflowId: record.id,
          canChangeWorkflow: false,
          canChangeRecord: true,
        });
      } else {
        initProcessWorkflowForm({
          record,
          canChangeRecord: false,
          canChangeWorkflow: true,
        });
      }
    }
  }

  private copyLinkToClipboard() {
    const { alertMessage } = this.props;
    navigator.clipboard.writeText(window.location?.href);
    alertMessage({
      body: 'Link to record copied to clipboard!',
      type: 'success',
    });
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  getRelatedRecordById: (payload: IGetRecordAssociationById) =>
    dispatch(getRecordAssociationByIdRequest(payload)),
  getRecord: (payload: IGetRecordById) => dispatch(getRecordByIdRequest(payload)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  restoreRecord: (payload: any, cb: any) => dispatch(restoreRecordByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getAssociations: (params: IGetRecordAssociations, db: any) =>
    dispatch(getRecordAssociationsRequest(params, db)),
  initializeCancelAppointment: (params: any) => dispatch(initailizeCancelAppointmentModal(params)),
  initializeSwapAddress: (params: IInitializeSwapAddress) =>
    dispatch(initializeSwapAddress(params)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  initProcessWorkflowForm: (params: any) => dispatch(initProcessWorkflowForm(params)),
  toggleAssignGroupModal: () => dispatch(toggleAssignRecordToGroupModal()),
});

export default withRouter(connect(mapState, mapDispatch)(ActionMenuDetailView));
