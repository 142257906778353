import { ButtonGroup } from '@blueprintjs/core';
import { httpGet } from '@core/http/requests';
import { MyCasesContext } from '@core/modules/SupportModule/views/MyCasesViewV2';
import CaseBlockedButton from '@core/modules/SupportModule/views/MyCasesViewV2/components/MyCasesBottomRowControl/CaseBlockedButton';
import PendingAgentButton from '@core/modules/SupportModule/views/MyCasesViewV2/components/MyCasesBottomRowControl/PendingAgentButton';
import RequestReviewButton from '@core/modules/SupportModule/views/MyCasesViewV2/components/MyCasesBottomRowControl/RequestReviewButton';
import SubmitCaseSolvedButton from '@core/modules/SupportModule/views/MyCasesViewV2/components/MyCasesBottomRowControl/SubmitCaseSolvedButton';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import CloseConversationButton from './CloseConversationButton';

const MyCasesBottomRowControl: React.FC = () => {
  const { state } = useContext(MyCasesContext);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);

  const { selectedCase } = state;

  useEffect(() => {
    if (selectedCase) {
      getPipeline();
    }
  }, [selectedCase]);

  const getPipeline = async () => {
    try {
      const res = await httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/SupportModule/Case${
          selectedCase?.type ? `?schemaType=${selectedCase?.type}` : '?schemaType='
        }`,
      );

      const pipeline = res.data?.data;
      if (pipeline?.length! > 0) {
        setPipeline(pipeline[0]);
      }
    } catch (error) {}
  };

  return (
    <Row align="middle" style={{ height: '100%', padding: '0 15px' }}>
      <Col span={12}>
        <CloseConversationButton />
      </Col>

      <Col span={12} style={{ textAlign: 'right' }}>
        <ButtonGroup>
          <CaseBlockedButton />

          {/* If status Pending Review - the "Request Review" button becomes "Pending Agent" */}
          {selectedCase?.stage?.key === 'CaseDefaultStagePendingReview' ? (
            <PendingAgentButton />
          ) : (
            <RequestReviewButton />
          )}
          <SubmitCaseSolvedButton />
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default MyCasesBottomRowControl;
