import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Image, Row } from 'antd';

import { NonIdealState, Section } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';

interface Props {
  file: DbRecordEntityTransform;
}

const FileDetailViewPreview: React.FC<Props> = (props: Props) => {
  const { file } = props;
  const [fileContent, setFileContent] = useState<string | null>(null);

  useEffect(() => {
    const MimeType = getProperty(file, 'Mimetype');

    if (MimeType === 'text/plain' || MimeType === 'text/html') {
      fetchFileViaURL();
    }
  }, [file]);

  const fetchFileViaURL = async () => {
    try {
      const response = await fetch(getProperty(file, 'Url'));
      const text = await response.text();
      setFileContent(text);
    } catch (error) {
      console.error('Error fetching the file:', error);
      setFileContent('Failed to load the file content.');
    }
  };

  const renderFile = () => {
    const MimeType = getProperty(file, 'Mimetype');
    const URL = getProperty(file, 'Url');

    // REGULAR IMAGE
    if (MimeType?.indexOf('image') > -1 && !URL.includes('.svg')) {
      return <Image src={getProperty(file, 'Url')} />;
    }
    // TXT
    if (MimeType === 'text/plain') {
      // retrieve text file
      return (
        <div style={{ padding: 20, minHeight: 200 }}>
          {fileContent ? fileContent : 'Loading...'}
        </div>
      );
    }
    if (MimeType === 'text/html') {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: fileContent || '<>Could not load HTML file.</>' }}
        />
      );
    }
    // PDF
    else if (MimeType === 'application/pdf') {
      return (
        <object width="100%" style={{ minHeight: 800 }} data={URL} type="application/pdf"></object>
      );
    }
    // MP3
    else if (MimeType === 'audio/mp3') {
      return (
        <div style={{ padding: 20 }}>
          <audio controls style={{ width: '100%' }}>
            <source src={URL} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      );
    }
    //image/svg+xml
    // else if (MimeType === 'image/svg+xml') {
    //   return (
    //     <>
    //       SVG
    //       <img src={getProperty(file, 'Url')} alt="" />
    //     </>
    //   );
    // }

    // MOV Quicktime
    else if (MimeType === 'video/quicktime') {
      return (
        <video width="100%" controls>
          <source src={URL} type="video/mp4" />
          Your browser does not support HTML5 video for MOV files.
        </video>
      );
    }
    // UNSUPPORTED
    else {
      return (
        <div style={{ height: 200 }}>
          <NonIdealState
            title="Unsupported File Extension"
            description={`Mimetype ${getProperty(
              file,
              'Mimetype',
            )} is currently unsupported in a file preview mode.`}
            icon="search"
          />
        </div>
      );
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Section title={'File Preview'}>{renderFile()}</Section>
      </Col>
    </Row>
  );
};

export default FileDetailViewPreview;
