import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { FC } from 'react';

interface Props {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  onPaginate: (nr: number) => void;
  disabled?: boolean;
}

export const BlueprintNavigation: FC<Props> = (props: Props) => {
  const { totalCount, currentPage, pageSize, disabled, onPaginate } = props;

  const canNavigateLeft = () => {
    return currentPage > 1;
  };

  const canNavigateRight = () => {
    return currentPage < Math.ceil(totalCount / pageSize);
  };

  const generatePages = () => {
    const totalPages = Math.ceil(totalCount / pageSize);
    if (totalPages <= 15) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const pages = [];
    pages.push(1);

    if (currentPage > 4) {
      pages.push('...');
    }

    for (
      let i = Math.max(2, currentPage - 2);
      i <= Math.min(totalPages - 1, currentPage + 2);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - 3) {
      pages.push('...');
    }

    pages.push(totalPages);
    return pages;
  };

  const pages = generatePages();

  return (
    <ButtonGroup>
      <Button
        icon="caret-left"
        disabled={!canNavigateLeft() || disabled}
        onClick={() => onPaginate(currentPage - 1)}
        small
      />
      {pages.map((pageNum, index) =>
        pageNum === '...' ? (
          <Button key={`ellipsis-${index}`} disabled small>
            ...
          </Button>
        ) : (
          <Button
            small
            key={pageNum}
            intent={pageNum === currentPage ? Intent.PRIMARY : Intent.NONE}
            onClick={() => onPaginate(pageNum as number)}
            disabled={disabled}
          >
            {pageNum}
          </Button>
        ),
      )}
      <Button
        small
        icon="caret-right"
        disabled={!canNavigateRight() || disabled}
        onClick={() => onPaginate(currentPage + 1)}
      />
    </ButtonGroup>
  );
};
