import { FC, useContext, useState, useEffect } from 'react';
import { Col, Row, Spin } from 'antd';
import { Button, Tooltip } from '@blueprintjs/core';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import HeaderDetailView from '@core/components/HeaderDetailView';
import RecordStageSequentialPipeline_V2 from '@core/components/RecordStageSequentialPipeline_V2';
import GanttChart from '@core/components/GanttChart';
import GanttActions from '@core/components/GanttActions';
import { GanttStatic } from '@dhx/trial-gantt';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { connect } from 'react-redux';
import { NEW_GANTT_TASK } from '@core/components/GanttChart/store/reducer';
import { hasPermissions } from '@core/helpers/rbacRules';
import TaskForm from './components/TaskForm';
import { GanttConfigOptions } from '@dhx/trial-gantt';
import { httpGet } from '@core/http/requests';
import { getErrorMessage } from '@core/modules/ControlPanelModule/utils/errors';
import { errorNotification } from '@legacy/core/notifications/store/reducers';

interface OwnProps {
  userReducer: any;
  showNewTaskLightbox: () => void;
  users: any[];
  setUsers: (users: any[]) => void;
  setLoadingUsers: (loading: boolean) => void;
  isLoadingUsers: boolean;
  notifyError: any;
  ganttInstance: GanttStatic;
}

type Props = OwnProps;

const GanttProjectDetailView: FC<Props> = (props) => {
  const { userReducer, showNewTaskLightbox, users, setUsers, setLoadingUsers, isLoadingUsers, notifyError, ganttInstance } = props;
  const { record, pipeline } = useContext(DetailViewContext);
  const [taskFormVisible, setTaskFormVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);

  const fetchGroupUsers = async () => {
    const groupId = record?.groups?.[0]?.id;
    setLoadingUsers(true);
    try {
      if (groupId) {
        const res = await httpGet(`IdentityModule/v2.0/groups/${groupId}/users`);
        setUsers(res.data.data || []);
      } else {
        notifyError({ message: 'Could not fetch owners. Please ensure the project has a group.' });
      }
    } catch (e: any) {
      const message = getErrorMessage(e);
      notifyError({ message: 'Could not fetch owners. ' + message });
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    if (record?.groups?.[0]?.id) {
      fetchGroupUsers();
    }
  }, [record?.groups]);

  const canUserAccessNewTaskAction = () => {
    return hasPermissions(userReducer, ['projectmodule.gantttask.create']);
  };

  const handleShowTaskForm = (taskId: string) => {
    setSelectedTaskId(taskId);
    setTaskFormVisible(true);
  };

  const handleCloseTaskForm = () => {
    setTaskFormVisible(false);
    setSelectedTaskId(null);
  };

  const ownerColumn = (users: any[]) => ({
    name: 'owner',
    label: 'Owner',
    align: 'center',
    width: 100,
    resize: true,
    template: function (task: any) {
      const owner = users.find((u: any) => u.id === task.owner_id);
      return owner ? `${owner.firstName} ${owner.lastName}` : '';
    }
  });

  const customGanttConfig = {
    showLightbox: handleShowTaskForm,
    locale: {
      labels: {
        section_owner: 'Owner'
      }
    },
    columns: [
      { name: 'text', tree: true, width: 200, resize: true },
      { name: 'start_date', label: 'Start', align: 'center', width: 100, resize: true },
      { name: 'duration', label: 'Days', align: 'center', width: 100, resize: true },
      ownerColumn(users),
    ],
    templates: {
      tooltip_text: function (start: Date, end: Date, task: any) {
        const owner = users.find((u: any) => u.id === task.owner_id);

        let html = `<b>Task:</b> ${task.text}<br/>`;
        html += `<b>Start Date:</b> ${start.toLocaleDateString()}<br/>`;
        html += `<b>End Date:</b> ${end.toLocaleDateString()}<br/>`;
        html += `<b>Duration in Days:</b> ${task.duration}<br/>`;
        html += `<b>Progress:</b> ${Math.floor(task.progress * 100)}%<br/>`;

        if (owner) {
          html += `<b>Owner:</b> ${owner.firstName} ${owner.lastName}<br/>`;
        }

        return html;
      },
      timeline_cell_class: function (task: any, date: Date) {
        return date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : '';
      }
    }
  } as unknown as GanttConfigOptions;

  if (isLoadingUsers || users.length === 0) {
    return (
      <Row style={{ paddingTop: 280, textAlign: 'center' }}>
        <Col span={24}>
          <Spin size="large" />
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <span style={{ fontSize: '1.5em' }}>Loading owners...</span>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row style={{ marginTop: 1 }}>
        <Col span={24}>
          <HeaderDetailView
            record={record!}
            extra={[
              <Row gutter={8}>
                <Col>
                  <Tooltip
                    fill
                    disabled={canUserAccessNewTaskAction()}
                    content="You don't have permissions to create tasks."
                  >
                    <Button
                      key="new-task"
                      intent="primary"
                      outlined
                      disabled={!canUserAccessNewTaskAction()}
                      onClick={() => showNewTaskLightbox()}
                    >
                      New Task
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            ]}
          />

          {record?.stage && pipeline?.isSequential && (
            <RecordStageSequentialPipeline_V2
              className="record-pipeline"
              record={record}
              pipeline={pipeline}
            />
          )}
        </Col>

        <Col span={24} style={{ padding: '10px 15px 0px 15px' }}>
          { ganttInstance && users && (
            <GanttActions record={record}/>
          )}
        </Col>

        <Col xs={24} style={{ padding: '0px 15px 15px 15px' }}>
          <GanttChart
            record={record}
            dataProcessorUrl={`${SchemaModuleTypeEnums.PROJECT_MODULE}/v1.0/GanttProject/${record?.id}`}
            config={customGanttConfig}
          />
        </Col>
      </Row>

      {taskFormVisible && selectedTaskId && (
        <TaskForm
          id={selectedTaskId}
          onClose={handleCloseTaskForm}
        />
      )}
    </>
  );
};

const mapDispatch = (dispatch: any) => ({
  showNewTaskLightbox: () => dispatch({ type: NEW_GANTT_TASK }),
  setUsers: (users: any[]) => dispatch({ type: 'SET_GANTT_USERS', payload: users }),
  setLoadingUsers: (loading: boolean) => dispatch({ type: 'SET_LOADING_USERS', payload: loading }),
  notifyError: (params: any) => dispatch(errorNotification(params)),
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  users: state.ganttReducer.users || [],
  isLoadingUsers: state.ganttReducer.isLoadingUsers || false,
  ganttInstance: state.ganttReducer.ganttInstance,
});

export default connect(mapState, mapDispatch)(GanttProjectDetailView);
