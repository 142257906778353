import { Button } from '@blueprintjs/core';
import RecordStageChangeDialog from '@core/components/SchemaActions/RecordStageChangeDialog';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../../index';

interface Props {
  userReducer: any;
}

const RequestReviewButton: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const isConversationClosed = (): boolean => {
    return (
      !state.selectedCaseConversation ||
      getProperty(state.selectedCaseConversation, 'Status') === 'CLOSED'
    );
  };

  const isActionDisabled = () => {
    return (
      !isCaseOwn ||
      !isConversationClosed() ||
      !state.selectedCase ||
      isDialogOpen ||
      ['CaseDefaultStagePendingReview', 'CaseDefaultStageClosed'].includes(
        state.selectedCase?.stage?.key!,
      )
    );
  };

  const closeModal = async () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <RecordStageChangeDialog
        openDialog={isDialogOpen}
        record={state.selectedCase!}
        targetStage={'CaseDefaultStagePendingReview'}
        onClose={closeModal}
        onConfirm={() => {
          setIsDialogOpen(false);
        }}
      />
      <Button
        outlined
        text="Request Review"
        style={{ marginRight: 8, borderRadius: 5 }}
        disabled={isActionDisabled()}
        onClick={() => setIsDialogOpen(true)}
      />
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(RequestReviewButton);
