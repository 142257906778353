import { Button, Section } from '@blueprintjs/core';
import { Col, Row, Table } from 'antd';
import { FC, useContext, useState } from 'react';
import { connect } from 'react-redux';

import { httpPost } from '@core/http/requests';
import { SchemaActionDefinitionContext } from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/ActionsSection/SchemaActionConfiguration';
import { displayMessage } from '@legacy/core/messages/store/reducers';

interface Props {
  alertMessage: any;
}

const SchemaActionPermissions: FC<Props> = (props: Props) => {
  const { alertMessage } = props;
  const [isRequestingAccessControl, setIsRequestingAccessControl] = useState<boolean>(false);
  const { schema, schemaAction } = useContext(SchemaActionDefinitionContext);

  const tableColumns = [
    { title: 'Name', dataIndex: 'name' },
    { title: 'Description', dataIndex: 'description' },
  ];

  const requestAccessControl = () => {
    setIsRequestingAccessControl(true);
    httpPost(
      `IdentityModule/v1.0/rbac/permissions/schemas-actions/batch/${schemaAction?.id}`,
      {},
    ).then((res: any) => {
      alertMessage({
        body: `Access Control Enabled`,
        type: 'success',
      });

      setIsRequestingAccessControl(false);
    });
  };

  return (
    <Row>
      <Col span={24} style={{ padding: 15 }}>
        <Section
          title="Permissions"
          rightElement={
            <Button
              icon="lock"
              intent="success"
              onClick={requestAccessControl}
              disabled={!schemaAction}
              loading={isRequestingAccessControl}
              text="Enable Access Control"
            />
          }
        >
          <Row style={{ margin: 1 }}>
            <Col span={24}>
              <Table
                size="small"
                columns={tableColumns as any}
                dataSource={schemaAction?.permissions || ([] as any)}
              />
            </Col>
          </Row>
        </Section>
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(SchemaActionPermissions);
