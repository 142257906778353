interface PasswordRule {
  title: string;
  regexp: RegExp;
}

export const passwordRules: PasswordRule[] = [
  {
    title: 'Password required',
    regexp: /.{1,}/,
  },
  {
    title: 'One Special character',
    regexp: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
  },
  {
    title: 'One Uppercase character',
    regexp: /[A-Z]/,
  },
  {
    title: 'One Lowercase character',
    regexp: /[a-z]/,
  },
  {
    title: 'One Digit or more',
    regexp: /[0-9]/,
  },
  {
    title: '12 characters or more',
    regexp: /.{12,}/,
  },
];

export const checkPasswordRule = (rule: PasswordRule, password: string) =>
  password.match(rule.regexp);

export const isValidPassword = (password: string) =>
  passwordRules.every((rule) => checkPasswordRule(rule, password));
