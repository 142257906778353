import { Col, Descriptions, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import HeaderDetailView from '@core/components/HeaderDetailView';
import SchemaActionPageHeader from '@core/components/SchemaActions/SchemaActionPageHeader';
import { Link } from 'react-router-dom';
import { Button, Section } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import NoteFeed from '@core/components/NoteFeed';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import CardWithTabs from '@legacy/components/CardWithTabs';
import ActivityCenter from '@legacy/core/records/components/ActivityCenter';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import { Avatar } from 'antd';
import { getInitialsFromName } from '@core/helpers/UIHelpers';
import { getPastelColorForUsername } from '@core/helpers/UIHelpers';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { getRecordAssociationsRequest, IGetRecordAssociations } from '@legacy/core/recordsAssociations/store/actions';
import { connect } from 'react-redux';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { isMobile } from 'react-device-detect';

interface Props {
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
}

const FeatureCard: React.FC<Props> = ({ getAssociations }) => {
  const { record, schema } = useContext(DetailViewContext);
  const [projectRecord, setProjectRecord] = useState<DbRecordEntityTransform | undefined>(undefined);

  const detailsTab = (
    <Row>
      <Col span={24}>
        {!isMobile ? (
          <Descriptions
            labelStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
              fontWeight: 500,
            }}
            contentStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
            }}
            className="recordProperties_small"
            column={isMobile ? 1 : 2}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Description">
              {getProperty(record, 'Description') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Reporter">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: getPastelColorForUsername(
                      record?.createdBy?.fullName || 'Unknown',
                    ),
                  }}
                >
                  {getInitialsFromName(record?.createdBy?.fullName || '')}
                </Avatar>
                {record?.createdBy?.fullName}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Structure Model">
              {getProperty(record, 'StructureModel') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Assignee">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Structure Type">
              {getProperty(record, 'StructureType') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Regional Programme Lead" contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Route Type">
              {getProperty(record, 'RouteType') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Build Contractor" contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="External Ref">
              {getProperty(record, 'ExternalRef') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="BAM" contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Exchange Name">
              {getProperty(record, 'ExchangeName') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Due Date">-</Descriptions.Item>

            <Descriptions.Item label="Exchange Number">
              {getProperty(record, 'ExchangeNumber') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Priority">-</Descriptions.Item>

            <Descriptions.Item label="Ex Polygon ID">
              {getProperty(record, 'ExPolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Part of SLA?">-</Descriptions.Item>

            <Descriptions.Item label="L1 Polygon ID">
              {getProperty(record, 'L1PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Commercial Rejection Reason">-</Descriptions.Item>

            <Descriptions.Item label="L2 Polygon ID">
              {getProperty(record, 'L2PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Splicing Affected">
              No
            </Descriptions.Item>

            <Descriptions.Item label="Closure ID">
              {getProperty(record, 'ClosureId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Est. Cost of Change">-</Descriptions.Item>
          </Descriptions>
        ) : (
          <Descriptions
            labelStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
              fontWeight: 500,
            }}
            contentStyle={{
              fontSize: '0.9em',
              padding: '4px 6px',
            }}
            className="recordProperties_small"
            column={isMobile ? 1 : 2}
            size="small"
            bordered={true}
          >
            <Descriptions.Item label="Description">
              {getProperty(record, 'Description') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Structure Model">
              {getProperty(record, 'StructureModel') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Structure Type">
              {getProperty(record, 'StructureType') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Route Type">
              {getProperty(record, 'RouteType') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="External Ref">
              {getProperty(record, 'ExternalRef') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Exchange Name">
              {getProperty(record, 'ExchangeName') || '-'}
            </Descriptions.Item>


            <Descriptions.Item label="Exchange Number">
              {getProperty(record, 'ExchangeNumber') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Ex Polygon ID">
              {getProperty(record, 'ExPolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="L1 Polygon ID">
              {getProperty(record, 'L1PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="L2 Polygon ID">
              {getProperty(record, 'L2PolygonId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Closure ID">
              {getProperty(record, 'ClosureId') || '-'}
            </Descriptions.Item>

            <Descriptions.Item label="Reporter">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar
                  size="small"
                  style={{
                    backgroundColor: getPastelColorForUsername(
                      record?.createdBy?.fullName || 'Unknown',
                    ),
                  }}
                >
                  {getInitialsFromName(record?.createdBy?.fullName || '')}
                </Avatar>
                {record?.createdBy?.fullName}
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Assignee">
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Regional Programme Lead" contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Build Contractor" contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="BAM" contentStyle={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                <Avatar size="small" src=""></Avatar>
                None
              </div>
            </Descriptions.Item>

            <Descriptions.Item label="Due Date">-</Descriptions.Item>

            <Descriptions.Item label="Priority">-</Descriptions.Item>

            <Descriptions.Item label="Part of SLA?">-</Descriptions.Item>

            <Descriptions.Item label="Commercial Rejection Reason">-</Descriptions.Item>

            <Descriptions.Item label="Splicing Affected">No</Descriptions.Item>

            <Descriptions.Item label="Est. Cost of Change">-</Descriptions.Item>
          </Descriptions>
        )}
      </Col>
    </Row >
  );

  useEffect(() => {
    if (record) {
      getAssociations({
        recordId: record.id,
        schema: schema,
        entities: ['Project'],
      }, (res: any) => {
        if (res?.results?.Project?.dbRecords?.length > 0) {
          setProjectRecord(res.results.Project.dbRecords[0]);
        }
      });
    }
  }, [record]);

  const getViewInMapPath = (record: DbRecordEntityTransform) => {
    if (record && record.properties.ExternalRef) {
      return `/${SchemaModuleTypeEnums.PROJECT_MODULE}/Map/${record?.type}/${record.properties.ExternalRef}`;
    }
  };

  const viewInMapPath = getViewInMapPath(record);

  return (
    <>
      <Row style={{ marginTop: 1 }}>
        <Col span={24}>
          <HeaderDetailView
            isQuickView={true}
            record={record}
            extra={[
              (
                <SchemaActionPageHeader
                  moduleName={schema.moduleName}
                  entityName={schema.entityName}
                  record={record}
                  launcherType="INLINE"
                />
              ),
              viewInMapPath ? (
                <Link target="_blank" to={viewInMapPath}>
                  <Button text="View in Map" intent="primary" outlined />
                </Link>
              ) : (
                <></>
              )
            ]}
          />
        </Col>
      </Row>

      <Row style={{ marginLeft: 15, marginRight: 15, marginTop: 15 }}>
        <Col span={24}>
          <CardWithTabs
            title="Feature Details"
            defaultTabKey="Details"
            hideCardHeader
            tabList={[
              { key: 'Details', tab: 'Details' },
              { key: 'Activity', tab: 'Activity' },
              { key: 'Files', tab: 'Files' },
              ...(projectRecord ? [{ key: 'ProjectDetails', tab: 'Project' }] : []),
            ]}
            tabContents={{
              Details: detailsTab,
              Activity: <ActivityCenter record={record} schema={schema} />,
              Files: <AssociationDataTable
                thumbnailSize={12}
                title="Files"
                record={record}
                showFileCategoryForType="DEFAULT"
                moduleName="SchemaModule"
                entityName="File"
                location="sidebar"
                collapsedByDefault
              />,
              ProjectDetails: projectRecord ? <RecordCard record={projectRecord} visibleProperties={['Contractor', 'PurchaseOrderNumber', 'EstimatedStart', 'EstimatedEnd']} /> : <></>,
            }}
          />
        </Col>
      </Row>

      <Row style={{ marginLeft: 15, marginRight: 15, marginBottom: 15 }}>
        <Col span={24}>
          <Section title="Notes">
            <NoteFeed record={record} />
          </Section>
        </Col>
      </Row>
    </>
  );
};

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) => dispatch(getRecordAssociationsRequest(params, cb)),
});

export default connect(null, mapDispatch)(FeatureCard);
