import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import React, { useState } from 'react';

interface Props {
  allEntityNames: string[];
  selectedEntityName: string;
  setSelectedEntityName: any;
  disabled?: boolean;
}

interface IEntity {
  key: string;
  name: string;
  value: string;
}

const SchemaActionEntityNameSelect: React.FC<Props> = (props: Props) => {
  const { allEntityNames, setSelectedEntityName, selectedEntityName, disabled } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');

  const renderEntity: ItemRenderer<IEntity> = (entity, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else
      return (
        <MenuItem
          active={entity.name === selectedEntityName}
          disabled={modifiers.disabled}
          key={entity.name}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={entity.name}
        />
      );
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const handleItemSelect = (item: IEntity) => {
    if (item.key === 'none') {
      setSelectedEntityName(undefined);
    } else {
      setSelectedEntityName(item.name);
    }
  };

  let ENTITIES: IEntity[] = allEntityNames.map((entity: string) => {
    return {
      key: entity,
      name: entity,
      value: entity,
    };
  });

  ENTITIES.sort((a: IEntity, b: IEntity) => {
    return a.name.localeCompare(b.name);
  });

  if (searchQuery.length > 0) {
    ENTITIES = ENTITIES.filter((e: IEntity) => {
      return e.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  // Add (None) to the top of the list
  if (!searchQuery.length) {
    ENTITIES.unshift({
      key: 'none',
      name: '(None)',
      value: '',
    });
  }

  ENTITIES = ENTITIES.slice(0, 100);

  return (
    <Select<IEntity>
      items={ENTITIES}
      resetOnClose
      disabled={disabled || allEntityNames.length === 0}
      itemRenderer={renderEntity}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      scrollToActiveItem
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        intent={selectedEntityName ? 'primary' : 'none'}
        alignText="left"
        disabled={disabled || allEntityNames.length === 0}
        text={selectedEntityName || 'Filter by Entity'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

export default SchemaActionEntityNameSelect;
