import { Result } from 'antd';

export const OrganizationSetupFinished: React.FC = () => {
  return (
    <div className="organization-setup-finished" style={{}}>
      <Result
        style={{ marginTop: 50 }}
        status="success"
        title="Organization Setup Finished"
        subTitle="You can now sign-in as the admin user you just created and start using the system."
        // extra={[
        //   <Link
        //     target="_blank"
        //     to={`/ProjectModule/WorkList/${workListData?.id}`}
        //   >
        //     View WorkList
        //   </Link>,
        // ]}
      />
    </div>
  );
};
