import { Row, Col } from 'antd';
import { DateTime } from 'luxon';
import { Icon } from '@blueprintjs/core';

export interface BoardCardProps {
  cardFields: any;
  selected?: boolean;
  dragging?: boolean;
  cardShape?: any;
}

const BoardCard: React.FC<BoardCardProps> = ({ cardFields, selected, dragging }) => {
  return (
    <Row>
      <Col span={24} className="wx-card__heading">
        <span className="wx-card__title">{cardFields.label}</span>
        <span className="wx-card__record-number">{cardFields.recordNumber}</span>
      </Col>

      <Col span={24} className="wx-card__description">
        {cardFields.description}
      </Col>

      <Col span={24} className="wx-card__tags">
        <div className="wx-card__tag wx-card__tag--primary">{cardFields.type}</div>

        <div className="wx-card__tag">
          <div className="wx-card__tag-icon"><Icon icon="small-plus" /></div>
          <div>{DateTime.fromISO(cardFields.createdAt).toFormat('d/M/yyyy')}</div>
        </div>
      </Col>
    </Row>
  );
};

export default BoardCard;
