import { Button, ButtonGroup, Card, CardList, Intent, Tag } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { LinksViewProps } from './types';

export const ListView = ({ links, onLinkClick, onLinkRemove, schema }: LinksViewProps) => {
  // console.log("[DebugX] links", links);
  return (
    <CardList bordered={false}>
      {links.map((link) => {
        const linkedSchema = link.childSchema || link.parentSchema;

        console.log('debug: linked schema', linkedSchema);
        console.log('debug: link', link);

        return (
          <Card key={link.id} interactive>
            {/* V2*/}
            <Row justify="space-between" align="middle" style={{ width: '100%' }}>
              {/* Left */}
              <Col>
                {/* Linked Schema Entity name */}
                <span style={{ fontWeight: 500 }}>{linkedSchema?.entityName}</span>

                {/* -> */}
                <span>
                  <i className="bi bi-arrow-right" style={{ marginLeft: 8 }} />
                </span>

                {/* Link type */}
                <Tag
                  intent="success"
                  round
                  minimal
                  fill={false}
                  style={{ verticalAlign: 'middle', marginLeft: 8 }}
                >
                  {link.type}
                </Tag>

                {/* -> */}
                <span>
                  <i className="bi bi-arrow-right" style={{ marginLeft: 8, marginRight: 8 }} />
                </span>

                {/* Schema entity */}
                <span style={{ fontWeight: 500 }}>{schema?.entityName}</span>

                {/* Label */}
                <span style={{ marginLeft: 20, opacity: 0.4 }}>{link?.label}</span>
              </Col>

              {/* Right */}
              <Col>
                <ButtonGroup>
                  <Button small minimal icon="annotation" onClick={() => onLinkClick(link)} />
                  <Button
                    icon="trash"
                    intent={Intent.DANGER}
                    minimal
                    small
                    onClick={() => onLinkRemove(link)}
                  />
                </ButtonGroup>
              </Col>
            </Row>
          </Card>
        );
      })}
    </CardList>
  );
};
