import { Button, Callout, Icon } from '@blueprintjs/core';
import {
  checkPasswordRule,
  isValidPassword,
  passwordRules,
} from '@core/modules/ControlPanelModule/containers/IdentityManager/UserLogin/containers/password';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { Col, Form, Input, Row } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

export type CreateUserDto = {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
};

type OrganizationAdminUserProps = {
  orgName: string;
  onSubmit: (user: Omit<OrganizationUserEntity, 'id'>) => Promise<void>;
  onBack: () => void;
};

export const OrganizationAdminUserForm: React.FC<OrganizationAdminUserProps> = ({
  orgName,
  onSubmit,
  onBack,
}) => {
  const formRef = useRef(null);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUserSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onSubmit({ firstname, lastname, email, password } as Omit<
        OrganizationUserEntity,
        'id'
      >);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
    }
  };

  const shouldEnableSubmit = useCallback(() => {
    return (
      firstname.length >= 2 &&
      lastname.length >= 2 &&
      email.length >= 5 &&
      isValidPassword(password) &&
      password === confirmPassword
    );
  }, [firstname, lastname, email, password, confirmPassword]);

  return (
    <Row
      style={{ background: 'white', height: 'calc(100vh - 40px)', overflowY: 'scroll' }}
      justify={'center'}
    >
      <Col xs={24} lg={14} style={{ padding: 15 }}>
        <h2>
          2. Setup Admin User for <span style={{ color: '#2D71D2' }}>{orgName}</span>
        </h2>
        <Form layout={'vertical'} style={{ paddingBottom: '1rem' }} ref={formRef}>
          <Row gutter={18} style={{ marginTop: 40 }}>
            <Col span={12}>
              {/* 1 */}
              <Form.Item
                className="form-item"
                label="First name"
                name="firstname"
                initialValue={firstname}
              >
                <Input
                  tabIndex={1}
                  placeholder="First name"
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 2 */}
              <Form.Item className="form-item" label="Password" name="password">
                <Input.Password
                  tabIndex={2}
                  autoComplete="new-password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <Icon icon="eye-open" /> : <Icon icon="eye-off" />
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 3 */}
              <Form.Item
                className="form-item"
                label="Last name"
                name="lastname"
                initialValue={lastname}
              >
                <Input
                  tabIndex={1}
                  placeholder="Last name"
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 4 */}
              <Form.Item
                className="form-item"
                label="Confirm Password"
                name="confirmPassword"
                initialValue={confirmPassword}
              >
                <Input.Password
                  tabIndex={2}
                  autoComplete="new-password"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <Icon icon="eye-open" /> : <Icon icon="eye-off" />
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* 5 */}
              <Form.Item className="form-item" label="E-mail" name="email" initialValue={email}>
                <Input
                  tabIndex={1}
                  placeholder="E-mail"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
              <Callout icon="key" title="Password Requirements">
                <Row style={{ marginTop: 25 }}>
                  {passwordRules.map((rule) => (
                    <Col
                      xs={24}
                      md={8}
                      key={rule.title}
                      style={{
                        color: checkPasswordRule(rule, password) ? 'green' : 'gray',
                        marginBottom: 15,
                      }}
                    >
                      {rule.title}{' '}
                      {checkPasswordRule(rule, password) ? (
                        <Icon icon="tick" intent="success" />
                      ) : (
                        <Icon icon="cross" intent="none" />
                      )}
                    </Col>
                  ))}
                </Row>
              </Callout>
            </Col>
          </Row>

          <Row gutter={12} justify="center" style={{ marginTop: 30 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button
                icon="caret-left"
                large
                key="1"
                intent="none"
                onClick={onBack}
                style={{ marginRight: 15 }}
                disabled={isSubmitting}
              >
                Back
              </Button>

              <Button
                large
                key="2"
                intent="primary"
                onClick={handleUserSubmit}
                loading={isSubmitting}
                disabled={!shouldEnableSubmit()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
